import React, { useState } from "react";
import PropTypes from "prop-types";
import QPFPHoveringMenuOpen from "../../assets/svgs/white-nav-shape.svg";
import QPFPHoveringMenuClosed from "../../assets/svgs/black-nav-shape.svg";
import { NonQpfpSidebarLinks, sidebarLinks } from "../../Constants";
import classNames from "classnames";
import CustomLink from "../../stories/Link";
import { useLocation } from "@reach/router";
import { useProfile } from "../../context/userProvider";
import Tour from "../../util/Tour";
import { useTourContext } from "../../context/tourProvider";

const SideNavMenu = ({ infoBottomCard }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { userDetails } = useProfile();
  const {
    state: { run, stepIndex, steps },
  } = useTourContext();

  return (
    <div
      className={`hidden fixed ${
        open ? "top-44" : "top-[calc(50%_-_12rem)]"
      } left-0 lg:flex justify-center z-50`}
    >
      <div
        className={open ? "block rounded-r-3xl bg-transparent" : "hidden"}
        style={{
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="rounded-tr-3xl pl-5 pr-24 py-4 bg-theme-black-400">
          <h3 className="font-semibold text-xl text-white">Menu</h3>
        </div>

        <div className="flex flex-col space-y-5 p-5 bg-white rounded-br-3xl w-64">
          {userDetails?.qpfp_certification_status !== "active"
            ? NonQpfpSidebarLinks.map((link, index) => {
                return (
                  <CustomLink
                    key={index}
                    href={link.href}
                    className={classNames(
                      "text-md cursor-pointer hover:underline text-theme-black-300 hover:text-theme-black-color",
                      location.pathname === link.href
                        ? "font-bold text-base text-theme-black-color"
                        : "font-light"
                    )}
                  >
                    {location.pathname === link.href && (
                      <span className="text-xs mr-2">&#9654;</span>
                    )}
                    {link.label}
                  </CustomLink>
                );
              })
            : sidebarLinks.map((link, index) => {
                return (
                  <CustomLink
                    key={index}
                    href={link.href}
                    className={classNames(
                      "text-md cursor-pointer hover:underline text-theme-black-300 hover:text-theme-black-color",
                      location.pathname === link.href
                        ? "font-bold text-base text-theme-black-color"
                        : "font-light"
                    )}
                  >
                    {location.pathname === link.href && (
                      <span className="text-xs mr-2">&#9654;</span>
                    )}
                    {link.label}
                  </CustomLink>
                );
              })}
          <div
            className={classNames(
              "flex items-center",
              !infoBottomCard && "hidden"
            )}
          >
            {infoBottomCard && infoBottomCard}
          </div>
        </div>
      </div>

      <div
        onClick={() => setOpen((prev) => !prev)}
        className="relative flex items-center cursor-pointer"
      >
        <img
          src={open ? QPFPHoveringMenuOpen : QPFPHoveringMenuClosed}
          alt="Hovering Menu Button"
        />
        <p
          className={classNames(
            "absolute left-0 font-bold text-lg transform -rotate-90",
            open ? "text-black" : "text-white"
          )}
        >
          {open ? "Close" : "Menu"}
        </p>
      </div>
      <Tour run={run} stepIndex={stepIndex} steps={steps} />
    </div>
  );
};

SideNavMenu.propTypes = {
  infoBottomCard: PropTypes.any,
};

export default SideNavMenu;
