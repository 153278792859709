import { Info } from "@phosphor-icons/react";
import React from "react";

const NonLoggedInUserBar = () => {
  return (
    <React.Fragment>
      <div className="flex flex-col justify-start p-4 rounded md:items-center bg-red-50 md:flex-row">
        <div className="flex items-start text-base font-medium leading-5 tracking-tight text-red-800">
          <Info
            size={20}
            weight="fill"
            className="mr-3 min-w-[20px] text-red-300"
          />{" "}
          <div className="block">Please Login to renew your membership</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NonLoggedInUserBar;
