import StyledButton from "../CommonComponents/StyledButton";
import CourseCard from "../CommonComponents/CourseCard";
import PropTypes, { object } from "prop-types";
import ResponsiveCardEncloser from "../CommonComponents/ResponsiveCardEncloser";
import classNames from "classnames";
import { useNavigate } from "@reach/router";

const CoursesSection = ({
  title,
  subTitle,
  secondaryInfo = "",
  background,
  courses,
  navigationLink,
  hideHeader = false,
  visitor = false,
  isComingSoon = false,
  myCourseSection = false,
  id = "",
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex items-center justify-center w-full py-10 px-5 ${background}`}
    >
      <div className="flex flex-col gap-5 max-w-7xl w-full" id={id}>
        <div
          className={`${
            hideHeader ? "hidden" : "flex"
          } items-center justify-between`}
        >
          <div className="flex flex-col items-start justify-start gap-1.5 w-3/4">
            <p className="text-xl md:text-2xl font-bold">{title ?? ""}</p>
            <p className="md:text-xl">{subTitle ?? ""}</p>
            {secondaryInfo?.length > 0 ? (
              <p className="text-xl text-black">{secondaryInfo}</p>
            ) : null}
          </div>
          {!isComingSoon && (
            <div className="">
              <StyledButton
                buttonStyle="primary"
                title={"View All"}
                handleClick={() => {
                  navigate(navigationLink);
                }}
              />
            </div>
          )}
        </div>
        {secondaryInfo?.length > 0 && hideHeader ? (
          <p className="text-xl text-black">{secondaryInfo}</p>
        ) : null}
        <div
          className={classNames(
            "xl:hidden",
            courses?.length < 2
              ? "xxs:hidden"
              : courses?.length < 3
              ? "sm:hidden"
              : "xl:hidden"
          )}
        >
          <ResponsiveCardEncloser>
            {courses?.length > 0 &&
              courses.map((course, index) => (
                <CourseCard
                  id={course?.id}
                  key={course?.id}
                  title={course?.title ?? course?.actual_name ?? ""}
                  subTitle={course?.description}
                  category={course?.category?.value}
                  image={course?.course_logo ?? ""}
                  status={
                    course?.has_started || myCourseSection
                      ? "inProgress"
                      : myCourseSection
                      ? "inProgress"
                      : "notStarted"
                  }
                  progress={course?.course_completion_percentage ?? 0}
                  rating={course?.average_rating}
                  lastActivity={course?.last_viewed_content?.last_seen}
                  courseType={course?.course_type}
                  courseSection={navigationLink}
                  duration={course?.course_duration}
                  level={course?.course_level}
                  visitor={visitor}
                  isComingSoon={isComingSoon}
                  isMyCourseCard={myCourseSection}
                />
              ))}
          </ResponsiveCardEncloser>
        </div>
        <div
          className={classNames(
            "hidden xl:grid grid-cols-4",
            courses?.length < 2
              ? "hidden xxs:flex justify-start gap-4 w-full"
              : courses?.length < 3
              ? "hidden xxs:flex justify-start gap-4 w-full"
              : "hidden xl:flex justify-start gap-4 w-full"
          )}
        >
          {courses?.length > 0 &&
            courses.map((course) => (
              <CourseCard
                id={course?.id}
                key={course?.id}
                title={course?.title ?? course?.actual_name ?? ""}
                subTitle={course?.description}
                category={course?.category?.value}
                image={course?.course_logo ?? course?.course_icon ?? ""}
                status={
                  course?.has_started || myCourseSection
                    ? "inProgress"
                    : myCourseSection
                    ? "notStarted"
                    : ""
                }
                progress={course?.course_completion_percentage ?? 0}
                rating={course?.average_rating}
                lastActivity={course?.last_viewed_content?.last_seen}
                courseType={course?.course_type}
                courseSection={navigationLink}
                duration={course?.course_duration}
                level={course?.course_level}
                visitor={visitor}
                isComingSoon={isComingSoon}
                isMyCourseCard={myCourseSection}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

CoursesSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  secondaryInfo: PropTypes.string,
  background: PropTypes.string,
  courses: PropTypes.arrayOf(object),
  navigationLink: PropTypes.string,
  hideHeader: PropTypes.bool,
  visitor: PropTypes.bool,
  isComingSoon: PropTypes.bool,
  myCourseSection: PropTypes.bool,
  id: PropTypes.string,
};
export default CoursesSection;
