import PropTypes from "prop-types";
import StyledButton from "../CommonComponents/StyledButton";
import ClientConnectCard from "../CommonComponents/ClientConnectCard";
import ResponsiveCardEncloser from "../CommonComponents/ResponsiveCardEncloser";
import classNames from "classnames";
import { useNavigate } from "@reach/router";
import { useProfile } from "../../context/userProvider";

const ClientConnectSection = ({
  title,
  subTitle,
  clientConnect,
  background,
}) => {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  return (
    <div
      className={`flex items-center justify-center w-full py-10 px-5 ${background}`}
    >
      <div className="flex flex-col gap-5 max-w-7xl w-full">
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-2 w-3/4">
            <p className="text-xl md:text-2xl font-bold">{title ?? ""}</p>
            <p className="md:text-xl">{subTitle ?? ""}</p>
          </div>
          <div className="">
            <StyledButton
              title={"View All"}
              handleClick={() => {
                navigate("/client-connect");
              }}
            />
          </div>
        </div>
        <div
          className={classNames(
            "xl:hidden",
            clientConnect?.length < 2
              ? "xxs:hidden"
              : clientConnect?.length < 3
              ? "md:hidden"
              : "xl:hidden"
          )}
        >
          <ResponsiveCardEncloser>
            {clientConnect.map((item, index) => (
              <ClientConnectCard
                isProMember={userDetails?.is_pro_member}
                isProMemberVisible={item?.pro_member_visible}
                key={"client-connect-" + index}
                title={item?.title}
                subTitle={item?.text_content}
                fileType={item?.post_content?.file_type ?? "File"}
                category={item?.category?.value}
                downloadLink={item?.post_content?.url}
                fileUrl={item?.post_content?.url}
                attachmentFileType={item?.post_content?.file_type ?? "File"}
                previewThumbnail={item?.post_thumbnail?.url}
              />
            ))}
          </ResponsiveCardEncloser>
        </div>
        <div
          className={classNames(
            "hidden xl:grid grid-cols-4",
            clientConnect?.length < 2
              ? "hidden xxs:flex gap-4"
              : clientConnect?.length < 3
              ? "hidden xxs:flex gap-4"
              : "hidden xl:flex gap-4"
          )}
        >
          {clientConnect.map((item, index) => (
            <ClientConnectCard
              isProMember={userDetails?.is_pro_member}
              isProMemberVisible={item?.pro_member_visible}
              key={"client-connect-" + index}
              title={item?.title}
              subTitle={item?.text_content}
              fileType={item?.post_content?.file_type ?? "File"}
              category={item?.category?.value}
              downloadLink={item?.post_content?.url}
              fileUrl={item?.post_content?.url}
              attachmentFileType={item?.post_content?.file_type ?? "File"}
              previewThumbnail={item?.post_thumbnail?.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

ClientConnectSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  clientConnect: PropTypes.array,
};
export default ClientConnectSection;
