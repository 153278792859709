import PropTypes from "prop-types";
import { formatDate } from "../../util/timeConverter";
import moment from "moment";
const LinearProgressBar = ({ lastActivity, progress = 0 }) => {
  return (
    <div className="flex flex-col gap-2.5 w-full">
      <div className="flex items-center justify-between text-sm tracking-tight">
        <p className="text-theme-gray-500">
          {moment(lastActivity) && `Last Viewed ${formatDate(lastActivity)}`}
        </p>
        <p className="text-theme-black-color">{progress}% Completed</p>
      </div>
      <div className="w-full bg-theme-gray-100 h-[3px] rounded">
        <div
          className="h-full bg-theme-orange rounded"
          style={{ width: `${Math.min(100, Math.max(0, progress))}%` }}
        ></div>
      </div>
    </div>
  );
};
LinearProgressBar.propTypes = {
  lastActivity: PropTypes.any,
  progress: PropTypes.any,
};
export default LinearProgressBar;
