import PropTypes from "prop-types";
import LandScapeCoursesCard from "./LandScapeCoursesCard";

const LandScapeCoursesCardSection = ({
  title,
  subTitle,
  background,
  liveCourses,
}) => {
  return (
    <div
      className={`flex items-center justify-center w-full py-10 mt-10 px-5 ${background}`}
    >
      <div className="flex flex-col gap-5 max-w-7xl w-full" id="live-courses">
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-2">
            <p className="text-2xl font-bold">{title ?? ""}</p>
            <p className="text-xl">{subTitle ?? ""}</p>
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-6 items-center justify-center">
          {liveCourses.map((course) => (
            <LandScapeCoursesCard
              key={course?.id}
              courseId={course?.id}
              title={course?.title}
              subTitle={course?.description}
              status={course?.has_started ? "inProgress" : ""}
              lastActivity={course?.last_viewed_content?.last_seen}
              progress={course?.course_completion_percentage ?? 0}
              thumbnail={course?.course_icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

LandScapeCoursesCardSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  liveCourses: PropTypes.array,
};
export default LandScapeCoursesCardSection;
