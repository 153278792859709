// /* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircleNotch, List, Lock } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import AppSkeleton from "../AppSkeleton";
import {
  useVisitorCourseCurriculum,
  useCourseLockDetail,
  useMyCoursesessionStats,
  useQPFPProgressTracker,
  useQPFPQuizTracker,
} from "../../services/CourseDetails";
import _ from "lodash";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import Button from "../../stories/Button";
import SearchBar from "../../stories/SearchBar";
import LockSessionModal from "../Modals/LockSessionModal";
import Loader from "../Shimmer/Loader";
import { navigate } from "@reach/router";
import { CustomToast } from "../../stories/Toast";
import { ErrorScreen } from "../../stories/ErrorScreen";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import PaymentModal from "../Modals/PaymentModal";
import { PaymentServices } from "../../services/PaymentServices";
import { useProfile } from "../../context/userProvider";
import ShowModules from "./ShowModules";
import QPFPProgressTracker from "./QPFPProgressTracker";
import qpfpCertificate from "../../assets/svgs/qpfp_certificate.svg";
import RenewModal from "../Modals/RenewModal";
import PropTypes from "prop-types";
import NavBar from "../CommonComponents/Navbar";
import SideNavMenu from "../Header/SideNavMenu";
import CourseDetailsHeroSection from "../CourseDetails/CourseDetailsHeroSection";
import CurriculumDetails from "../CourseDetails/CurriculumDetails";
import RatingsAndReviews from "../CourseDetails/RatingsAndReviews";
import ProMemberRegisterModal from "../Modals/ProMemberRegisterModal";
import { User } from "../../store";
import ProUpgradeCard from "../../stories/ProUpgradeCard";
import StyledButton from "../CommonComponents/StyledButton";
import Sidebar from "../../stories/Sidebar";
import {
  ArrowsCounterClockwise,
  CalendarBlank,
  Certificate,
  Desktop,
  Files,
  Folders,
  Users,
} from "@phosphor-icons/react";
import networkfp_logo from "../../assets/images/nfp.png";
import ImageComponent from "../../stories/ImageComponent";

const CirriculumPage = (props) => {
  const isLoggedIn = !!User.getToken();
  const [searchQuery, setSearchQuery] = useState("");
  const [showLockModal, setShowLockPopup] = useState(false);
  const [isCourseCompleted, setIsCourseCompleted] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [modalId, setModalId] = useState("");
  const [defaultState, setDefaultState] = useState("");
  const { userDetails } = useProfile();
  const sidebarList = [
    {
      id: "dashboard",
      label: "Dashboard",
      normalIcon: <Desktop className="menu-icon" />,
      activeIcon: <Desktop className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/",
    },
    {
      id: "my_programs",
      label: "My Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/programs",
    },
    {
      id: "qpfp_certificant",
      label: "My QPFP Certification",
      normalIcon: <Certificate className="menu-icon" />,
      activeIcon: <Certificate className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/qpfp-certificant/dashboard",
      hide: userDetails?.qpfp_certification_status === "inactive",
    },
    {
      id: "all_programs",
      label: "All Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/all-courses",
    },
    {
      id: "nfp_toolbox",
      label: "NFP Toolbox & Resources",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/toolbox",
    },
    {
      id: "nfp-calendar",
      label: "My Calendar",
      normalIcon: <CalendarBlank className="menu-icon" />,
      activeIcon: <CalendarBlank className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/nfp-calendar",
    },
    {
      id: "nfp-guest-list",
      label: "My Guest List",
      normalIcon: <List className="menu-icon" />,
      activeIcon: <List className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/guest-list",
    },
    {
      id: "payments",
      label: "My Payments",
      normalIcon: <Files className="menu-icon" />,
      activeIcon: <Files className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/payments",
    },
    {
      id: "public_profile",
      label: "Public Profile",
      normalIcon: <Users className="menu-icon" />,
      activeIcon: <Users className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/public-profile",
    },
    {
      id: "exchange-forum",
      label: "Exchange forum",
      normalIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      activeIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      dropdownList: [],
      path: "/exchange",
    },
  ];
  useEffect(() => {
    sidebarList.forEach((item) => {
      if (item.dropdownList?.length === 0) {
        if (item.path === window.location.pathname) {
          setDefaultState(item.id);
        }
      } else {
        item.dropdownList.forEach((e, i) => {
          if (e.path === window.location.pathname) {
            setDefaultState(e.id);
          }
        });
      }
    });
  }, [window?.location?.pathname]);

  const [openCertificatPaymentModal, setOpenCertificatePaymentModal] =
    useState(-1);
  const {
    curriculum,
    courseFees,
    eventDetails,
    lastViewContentDetails,
    hasStarted,
    hasRegistered,
    hasReviewed,
    ratingCount,
    loading: isLoading,
    error,
    mutate: mutateCurriculum,
  } = useVisitorCourseCurriculum(props.course_id, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const { qpfpQuizTracker, loading: isQuizTrackerLoading } = useQPFPQuizTracker(
    curriculum?.course_type,
    props.course_id,
    {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    }
  );

  const { qpfpProgressTracker, loading: isProgressTrackerLoading } =
    useQPFPProgressTracker(curriculum?.course_type, props.course_id, {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    });
  const showClientConnect =
    ["provisional", "approved", "expires_soon", "overdue"].includes(
      userDetails?.pm_signup_status
    ) && curriculum?.course_type?.includes("pro_member");
  const showProtools =
    (["approved", "expires_soon", "provisional", "overdue"].includes(
      userDetails?.pm_signup_status
    ) ||
      ["approved", "expires_soon", "overdue"].includes(
        userDetails?.qpfp_signup_status
      ) ||
      ["approved", "expires_soon", "overdue"].includes(
        userDetails?.provisonal_member_signup_status
      )) &&
    (curriculum?.course_type === "qpfp" ||
      curriculum?.course_type === "provisional_member" ||
      curriculum?.course_type?.includes("pro_member"));

  const { course_session_stats } = useMyCoursesessionStats(props.course_id, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });

  const curriculumRef = useRef(curriculum);

  const { course_lock_details, mutate: mutateCourseLockDetail } =
    useCourseLockDetail(props.course_id, {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    });

  const [sessionCountList, setSessionCountList] = useState([0]);
  const [filteredModules, setFilteredModules] = useState([]);
  useEffect(() => {
    curriculumRef.current = curriculum;
    curriculum?.modules?.forEach((module) => {
      setSessionCountList((prev) => {
        return [...prev, _.last(prev) + (module.sessions?.length ?? 0)];
      });
    });
  }, [curriculum]);

  // Sets the progress of the course
  useEffect(() => {
    if (!_.isEmpty(curriculum) && !_.isEmpty(course_session_stats)) {
      let flag = true;
      if (curriculum?.course_type === "qpfp") {
        setIsCourseCompleted(
          userDetails?.qpfp_certification_status !== "inactive"
        );
        return;
      }
      if (curriculum?.modules) {
        flag =
          curriculum?.modules?.filter((module) => {
            let isModuleCompleted = true;
            if (module.sessions) {
              isModuleCompleted =
                module?.sessions?.filter((session) => {
                  return course_session_stats[session.id] !== 100;
                }).length === 0;
            } else {
              isModuleCompleted = false;
            }
            return isModuleCompleted;
          }).length === curriculum?.modules.length;
      } else {
        flag = false;
      }
      setIsCourseCompleted(flag);
    }
  }, [curriculum, course_session_stats]);

  // Handles search
  useEffect(() => {
    if (_.isEmpty(searchQuery)) {
      setFilteredModules([]);
      return;
    }
    const tempModules = [];
    curriculum?.modules.forEach((module) => {
      if (module.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        tempModules.push(module);
        return;
      }
      const tempSessions = [];
      module.sessions.forEach((session) => {
        if (
          `${session.session_date} ${session.presenter} ${session.name}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        ) {
          tempSessions.push(session);
          return;
        }
        const tempContent = [];
        session.session_content.forEach((content) => {
          if (
            `${content.title} ${content.author_name} ${content.description}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ) {
            tempContent.push(content);
          }
        });
        if (tempContent.length > 0) {
          tempSessions.push({ ...session, session_content: tempContent });
        }
      });
      if (tempSessions.length > 0) {
        tempModules.push({ ...module, sessions: tempSessions });
      }
    });
    setFilteredModules(tempModules);
  }, [searchQuery]);

  useDocumentTitle(curriculum?.name ?? "Curriculum");

  const pageData = _.isEmpty(curriculum)
    ? []
    : [
        { name: "Dashboard", to: "/" },
        { name: "My Programs", to: "/programs" },
        {
          name: curriculum?.name,
          to: `/programs/${props.course_id}`,
          current: true,
        },
      ];

  useEffect(() => {
    if (error) {
      CustomToast.error(error.error_messages?.[0]);
      navigate("/");
    }
  }, [error]);

  const handleRenewModal = (eventModalId) => {
    setModalId(eventModalId);
    setShowRenewModal(!showRenewModal);
  };
  const GetCourseCertificate = () => {
    if (curriculum?.course_type !== "qpfp") return null;
    return (
      <div className="p-6">
        <div className="flex items-center justify-between w-full py-5 px-7 space-x-3 rounded-xl bg-theme-yellow/20">
          <div className="flex items-center">
            <img src={qpfpCertificate} className="w-14 h-14" alt="QPFP badge" />
            <div className="ml-2 text-base font-bold text-theme-black-color">
              {userDetails?.qpfp_certification_status === "inactive"
                ? "Apply for QPFP Certification"
                : "QPFP Certification"}
            </div>
          </div>

          <Button
            className="md:max-w-[250px] w-full !h-8 lg:!h-10"
            onClick={async () => {
              if (!isCourseCompleted) return;
              try {
                if (curriculum?.course_type === "qpfp") {
                  if (
                    userDetails?.qpfp_certification_status === "not_registered"
                  ) {
                    navigate("/qpfp-certificant/dashboard");
                    return;
                  }
                  navigate("/qpfp-certificant/dashboard");
                  return;
                }
                await PaymentServices.fetchPricingDetails({
                  entity_type: "course",
                  entity_id: curriculum["id"],
                  part_payment_order: 0,
                });
                try {
                  const response =
                    await PaymentServices.addUserExternalPurchaseDetails({
                      user_external_purchase_detail: {
                        entity_type: "Course",
                        entity_id: curriculum["id"],
                      },
                    });
                  setOpenCertificatePaymentModal(response.entity_id);
                } catch (e) {
                  console.log(e);
                  if (e.error_code === "payment_already_made") {
                    // already paid
                    navigate(`/programs/${curriculum?.id}/certificates`, {
                      state: {
                        course_id: curriculum?.id,
                        course_name: curriculum?.title,
                      },
                    });
                  } else {
                    // something else
                    CustomToast.error("Something went wrong");
                  }
                }
              } catch (e) {
                if (e.error_code === "pricing_not_found") {
                  // free certification - no need to pay
                  navigate(`/programs/${curriculum?.id}/certificates`, {
                    state: {
                      course_id: curriculum?.id,
                      course_name: curriculum?.title,
                    },
                  });
                }
              }
            }}
          >
            <div className="line-clamp-1 flex items-center overflow-ellipsis">
              {!isCourseCompleted
                ? "Apply after Finals"
                : userDetails?.qpfp_certification_status === "inactive"
                ? "Apply for Certification"
                : "View QPFP Dashboard"}
              {!isCourseCompleted && (
                <Lock
                  weight="fill"
                  className="text-white w-4 h-4 min-w-[16px] ml-2 inline-flex"
                />
              )}
            </div>
          </Button>
        </div>
      </div>
    );
  };

  if (isLoading && searchQuery === "") {
    return (
      <AppSkeleton hideSidebar={true} breadCrumbData={pageData}>
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </AppSkeleton>
    );
  }
  if (error) {
    return (
      <AppSkeleton hideSidebar={true}>
        <ErrorScreen />
      </AppSkeleton>
    );
  }

  return (
    <div className="bg-theme-gray-25">
      <div className="bg-theme-gray-25">
        <NavBar
          mobileSideBarComponent={
            <Sidebar
              className={`fixed inset-0 z-50 flex lg:hidden bg-white transition-transform duration-300 ${"translate-x-0"}`}
              showRegisterCard={true}
              isLogoClickable={true}
              sidebarItems={sidebarList}
              onMenuClick={async (item) => {
                await navigate(item.path);
                setDefaultState(item.id);
                // setIsSidebarOpen(false); // Close sidebar on navigation
              }}
              infoBottomCard={
                userDetails?.side_bar_promotion_cta && (
                  <ProUpgradeCard
                    className="bg-theme-gray-200"
                    heading={userDetails?.side_bar_promotion_cta?.heading}
                    description={
                      userDetails?.side_bar_promotion_cta?.description
                    }
                    cta={
                      <StyledButton
                        title={userDetails?.side_bar_promotion_cta?.cta_label}
                        handleClick={() => {
                          navigate(
                            userDetails?.side_bar_promotion_cta?.cta_link
                          );
                          // setIsSidebarOpen(false); // Close sidebar
                        }}
                      />
                    }
                    content_label={userDetails?.side_bar_promotion_cta?.title}
                    benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                      "\n"
                    )}
                  />
                )
              }
              defaultState={defaultState}
            />
          }
        />
        <div className="hidden h-max lg:flex flex-col">
          {isLoggedIn && (
            <SideNavMenu
              infoBottomCard={
                userDetails?.side_bar_promotion_cta && (
                  <ProUpgradeCard
                    className="bg-theme-gray-200"
                    heading={userDetails?.side_bar_promotion_cta?.heading}
                    description={
                      userDetails?.side_bar_promotion_cta?.description
                    }
                    cta={
                      <StyledButton
                        title={userDetails?.side_bar_promotion_cta?.cta_label}
                        handleClick={() => {
                          navigate(
                            userDetails?.side_bar_promotion_cta?.cta_link
                          );
                        }}
                      />
                    }
                    content_label={userDetails?.side_bar_promotion_cta?.title}
                    benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                      "\n"
                    )}
                  />
                )
              }
            />
          )}
        </div>
        {curriculum?.course_type === "qpfp" ? null : (
          <CourseDetailsHeroSection
            id={curriculum?.id}
            category={curriculum?.category}
            ratings={curriculum?.average_rating}
            thumbnail={curriculum?.course_logo}
            lastViewContentTitle={""}
            courseType={curriculum?.course_type}
            hasStarted={hasStarted}
            hasRegistered={hasRegistered}
            title={curriculum?.name}
            subTitle={curriculum?.description}
            duration={curriculum?.course_duration}
            level={curriculum?.course_level}
            contents={curriculum?.contents}
            pricing={courseFees}
            eventId={curriculum?.event?.id}
          />
        )}
        <div className={`flex items-center justify-center w-full py-10 px-7`}>
          <div className="flex flex-col gap-10 max-w-8xl w-full">
            {curriculum?.course_type === "qpfp" ||
            curriculum?.highlights.length === 0 ? null : (
              <div className="flex justify-between items-center">
                <CurriculumDetails points={curriculum?.highlights} />
                <div className="hidden lg:flex-1 lg:block" />
              </div>
            )}

            <React.Fragment>
              <div className="flex flex-col w-full lg:bg-white">
                {curriculum?.course_type === "qpfp" ? (
                  <div className="flex-col items-start justify-between hidden w-full px-6 lg:flex lg:flex-row">
                    <div className="flex flex-col text-2xl font-bold tracking-tight basis-3/5 text-theme-black-color">
                      {curriculum?.name}
                      <div className="flex items-center justify-start text-sm font-semibold leading-none text-theme-black-color">
                        <div className="w-8 h-8 mt-1 lg:mt-0.5 mr-2.5 bg-red-700 rounded-full">
                          <ImageComponent
                            src={networkfp_logo}
                            className="object-cover w-full h-full rounded-full"
                          />
                        </div>
                        Network FP
                      </div>
                    </div>
                    <div className="flex flex-col-reverse justify-end xl:flex-row basis-1/2">
                      <SearchBar
                        className="w-full !mt-3 basis-1/2 xl:!mt-0 xl:mr-3"
                        placeholder={"Search by Topic or Trainer"}
                        searchTerm={searchQuery}
                        onSearch={(val) => setSearchQuery(val)}
                      />
                    </div>
                  </div>
                ) : null}
                {eventDetails && eventDetails.venue && (
                  <div className="flex flex-col items-start justify-between w-full px-6 mt-4">
                    <div className="flex text-sm font-medium tracking-tight text-theme-gray-500">
                      Venue Details
                    </div>
                    <div className="flex mt-1 text-base font-medium tracking-tight text-theme-black">
                      {eventDetails.venue?.name},{" "}
                      {eventDetails.venue?.address?.line_1},{" "}
                      {eventDetails.venue?.address?.city},{" "}
                      {eventDetails.venue?.address?.state} -{" "}
                      {eventDetails.venue?.address?.pin}
                    </div>
                  </div>
                )}
                <div className="flex flex-col">
                  {isLoading ? (
                    <CircleNotch
                      className="mx-auto animate-spin"
                      size={20}
                      weight="fill"
                    />
                  ) : !_.isEmpty(searchQuery) && _.isEmpty(filteredModules) ? (
                    <div className="w-full p-3 mt-6 text-center lg:p-6">
                      <EmptyState
                        heading="No Result found!"
                        subHeading={
                          "Please modify your search to view result or click button below to resume learning"
                        }
                        cta={
                          <Button
                            className="!mt-4"
                            onClick={() => setSearchQuery("")}
                          >
                            <div className="flex items-center px-3 py-1">
                              <span>RESUME LEARNING</span>
                            </div>
                          </Button>
                        }
                        image={
                          <img
                            alt="emptyStatePlaceHolder"
                            className="py-9 max-h-80"
                            src={EmptyStatePlaceHolder}
                          />
                        }
                      />
                    </div>
                  ) : _.isEmpty(curriculum?.modules) ? (
                    <div className="w-full p-3 mt-6 text-center lg:p-6">
                      <EmptyState
                        heading="Hold on! Curriculum will be updated soon"
                        subHeading={
                          "We are working hard to make the curriculum better and it will be updated soon here."
                        }
                        cta={<></>}
                        image={
                          <img
                            alt="emptyStatePlaceHolder"
                            className="py-9 max-h-80"
                            src={EmptyStatePlaceHolder}
                          />
                        }
                      />
                    </div>
                  ) : null}
                  {_.isEmpty(searchQuery) && (
                    <QPFPProgressTracker
                      curriculum={curriculum}
                      isLoading={
                        isQuizTrackerLoading || isProgressTrackerLoading
                      }
                      qpfpQuizTracker={qpfpQuizTracker}
                      qpfpProgressTracker={qpfpProgressTracker}
                      lastViewContentDetails={lastViewContentDetails}
                      onLockedClick={(e) => {
                        handleRenewModal(e);
                      }}
                    />
                  )}
                  {/* {_.isEmpty(searchQuery) &&
                    (showClientConnect || showProtools) && (
                      <MembershipBenefit
                        curriculumRef={curriculumRef}
                        allowUserToSkipRenewal={
                          course_lock_details.has_expired &&
                          course_lock_details.grace_period
                        }
                        isLocked={course_lock_details.has_expired}
                        availablePartPaymentOptions={
                          course_lock_details.available_part_payment_option
                        }
                        refreshCallback={() => {
                          mutateCurriculum();
                          mutateCourseLockDetail();
                        }}
                        remainingAmount={
                          course_lock_details?.remaining_course_fees ?? 0
                        }
                        hasMadeFullPayment={
                          curriculum?.course_type === "qpfp"
                            ? course_lock_details?.has_made_full_payment ??
                              false
                            : !course_lock_details.has_expired
                        }
                        eventDetails={eventDetails}
                        entityType={curriculum?.course_type}
                        courseId={props.course_id}
                        showClientConnect={showClientConnect}
                        showProtools={showProtools}
                        openLockPopup={() => setShowLockPopup(true)}
                      />
                    )} */}
                  <div className="border border-black-opacity-10">
                    <ShowModules
                      curriculum={curriculum}
                      modules={
                        _.isEmpty(searchQuery)
                          ? curriculum?.modules
                          : filteredModules
                      }
                      curriculumRef={curriculumRef}
                      isMembershipBenefitVisible={
                        showClientConnect || showProtools
                      }
                      eventDetails={eventDetails}
                      allowUserToSkipRenewal={
                        course_lock_details.has_expired &&
                        course_lock_details.grace_period
                      }
                      availablePartPaymentOptions={
                        course_lock_details.available_part_payment_option
                      }
                      remainingAmount={
                        course_lock_details?.remaining_course_fees ?? 0
                      }
                      courseSessionStats={course_session_stats}
                      courseName={curriculum?.name}
                      entityType={curriculum?.course_type}
                      entityId={curriculum?.entity_id}
                      refreshCallback={() => {
                        window.location.reload();
                      }}
                      sessionCountList={sessionCountList}
                      isCourseRegistered={
                        curriculum?.course_type?.includes("pro_member")
                          ? userDetails?.is_pro_member
                            ? hasRegistered
                            : false
                          : hasRegistered
                      }
                    />
                  </div>
                  <RenewModal
                    key={showRenewModal}
                    open={showRenewModal}
                    entityType={curriculum?.course_type}
                    entityId={curriculum?.entity_id}
                    allowUserToSkipRenewal={
                      course_lock_details.has_expired &&
                      course_lock_details.grace_period
                    }
                    onClickSkipRenewal={() => {
                      navigate(
                        "/programs/" + curriculum?.id + "/learn/" + modalId,
                        {
                          state: {
                            curriculumData: {
                              course: curriculumRef?.current,
                              event_details: eventDetails,
                            },
                          },
                        }
                      );
                    }}
                    courseName={curriculum?.name}
                    remainingAmount={
                      course_lock_details?.remaining_course_fees ?? 0
                    }
                    availablePartPaymentOptions={
                      course_lock_details.available_part_payment_option
                    }
                    refreshCallback={() => {
                      mutateCurriculum();
                      mutateCourseLockDetail();
                      window.location.reload();
                    }}
                    onClose={() => {
                      setShowRenewModal(false);
                    }}
                  />
                  <div className="flex-col w-full gap-3 flex">
                    <GetCourseCertificate />
                  </div>
                </div>
              </div>
              {showLockModal && (
                <LockSessionModal
                  open={showLockModal}
                  courseId={props.course_id}
                  event_type={curriculum?.course_type}
                  dateTime={eventDetails.start_time}
                  onClose={() => setShowLockPopup(false)}
                />
              )}
              {openCertificatPaymentModal !== -1 && (
                <PaymentModal
                  open={openCertificatPaymentModal !== -1}
                  title={`Certification Fee for ${curriculum["name"]}`}
                  entityProps={{
                    entity_type: "course",
                    entity_id: curriculum["id"],
                    part_payment_order: 0,
                  }}
                  paymentEntityProps={{
                    entity_type: "user_external_purchase_detail",
                    entity_id: openCertificatPaymentModal,
                  }}
                  availablePartPaymentOptions={[
                    { label: "", part_payment_order: 0 },
                  ]}
                  onPaymentSuccessCallback={() => {
                    setOpenCertificatePaymentModal(-1);
                    navigate(`/programs/${curriculum?.id}/certificates`, {
                      state: {
                        course_id: curriculum?.id,
                        course_name: curriculum?.title,
                      },
                    });
                  }}
                  onClose={() => {
                    setOpenCertificatePaymentModal(-1);
                  }}
                />
              )}
            </React.Fragment>
            {ratingCount > 0 && (
              <RatingsAndReviews
                averageRating={curriculum?.average_rating}
                reviewCount={ratingCount}
                // ratings={curriculum?.ratings}
                contentId={curriculum?.id}
                hasRegistered={hasRegistered}
                hasReviewed={hasReviewed}
              />
            )}
          </div>
        </div>
      </div>
      <ProMemberRegisterModal />
    </div>
  );
};

CirriculumPage.propTypes = {
  course_id: PropTypes.string,
  location: PropTypes.object,
};

export default CirriculumPage;
