import TopQuestionAsked from "./TopQuestionAsked";
import PropTypes from "prop-types";
import { useProfile } from "../../context/userProvider";
import StyledButton from "./StyledButton";
import { navigate } from "@reach/router";
import { useForums } from "../../services/ForumDetails";
import { timeAgo } from "../../util/timeConverter";

const CommunityAndForum = () => {
  const { userDetails } = useProfile();
  const { forums, loading, error } = useForums({
    per_page: 1,
    page: 1,
    search: "",
    category_ids: "",
    sort_by: "most_recent",
  });
  return (
    <div className="w-full max-w-[623px] px-4 md:px-9 py-3 md:py-7 flex flex-col justify-between gap-5 border rounded-[10px]">
      <div className="flex flex-col gap-3">
        <p className="font-bold text-2xl lg:text-[28px]">Community Q&A Forum</p>
        <p className="text-theme-black-300 lg:text-lg">
          Have a Personal Finance related Query? Reach out to 1,000+ ProMembers
          for guidance.
        </p>
      </div>
      {!loading && !error && forums?.length > 0 ? (
        <TopQuestionAsked
          userAvatar={forums[0]?.user_detail?.profile_picture ?? ""}
          postedBy={forums[0]?.user_detail?.first_name || ""}
          postedOn={timeAgo(forums[0]?.created_at) ?? "a month ago"}
          question={forums[0]?.description}
          title={forums[0]?.title}
          answerAction={() => {
            navigate("/exchange");
          }}
          isProMember={userDetails?.is_pro_member}
        />
      ) : (
        <div className="max-w-52">
          <StyledButton
            title={"View Forum"}
            handleClick={() => {
              navigate("/exchange");
            }}
          />
        </div>
      )}
    </div>
  );
};

CommunityAndForum.propTypes = {
  userAvatar: PropTypes.string,
  postedBy: PropTypes.string,
  postedOn: PropTypes.string,
  question: PropTypes.string,
  answerAction: PropTypes.func,
};

export default CommunityAndForum;
