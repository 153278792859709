import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import { CaretCircleLeft, CaretCircleRight, User } from "@phosphor-icons/react";
import Marquee from "react-fast-marquee";

import { PopupType, useLoginModal } from "../../context/loginModalProvider";
import { EventsService } from "../../services/EventsService";
import { Loader } from "@storybook/components";
import { ContentService } from "../../services/ContentService";
import { getPartnerSupporterURL } from "../../util/getURLs";
import { useAllCourses } from "../../services/CourseDetails";
import usePosts from "../../hooks/usePost";

import LoginUserModal from "../Modals/LoginUserModal";
import SignupUserModal from "../Modals/SignupUserModal";
import ProMemberRegisterModal from "../Modals/ProMemberRegisterModal";
import StyledButton from "../CommonComponents/StyledButton";
import ChoosePlan from "../LandingPage/ChoosePlan";
import ImageComponent from "../../stories/ImageComponent";
import CoursesSection from "../LandingPage/CoursesSection";
import ProToolSection from "../CommonComponents/ProToolSection";
import QPFPSection from "../CommonComponents/QPFPBanner";
import StarRatings from "../StarRatings";

import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import networkfp_profile_strip from "../../assets/images/nfp_profile_strip.png";
import networkfp_profile_image from "../../assets/images/nfp_profile_page_image.png";
import networkfp_profile_check from "../../assets/svgs/nfp-profile-page-check.svg";
import qpfp_profile_education from "../../assets/svgs/qpfp-profile-education.svg";
import qpfp_profile_oath from "../../assets/svgs/qpfp-profile-oath-alt.svg";
import qpfp_profile_yoe from "../../assets/svgs/qpfp-profile-yoe.svg";
import trustpilot from "../../assets/images/visitor-trustpilot.png";
import googleRatings from "../../assets/images/visitor-google.png";
import heart from "../../assets/svgs/heart.svg";
import partner_placeholder from "../../assets/svgs/partner_placeholder.svg";
import banner from "../../assets/images/dashboard-hero-banner.png";

const NavBar = ({ handleOpenLogin, handleOpenSignup }) => (
  <div className="flex flex-row items-center justify-between px-5 py-4 lg:px-20 bg-white">
    <div>
      <img alt="network fp logo" src={networkfp_logo} className="h-9 lg:h-12" />
    </div>
    <div className="flex gap-3 lg:gap-6">
      <StyledButton
        buttonStyle="transparent-primary"
        title="Login"
        customClasses="font-medium"
        padding="p-0"
        handleClick={handleOpenLogin}
      />
      <StyledButton
        buttonStyle="outline-primary"
        title="Join for Free"
        customClasses="font-medium rounded-md"
        padding="px-6 py-3"
        handleClick={handleOpenSignup}
      />
    </div>
  </div>
);

NavBar.propTypes = {
  handleOpenLogin: PropTypes.func.isRequired,
  handleOpenSignup: PropTypes.func.isRequired,
};

const HeroSection = ({ whoArr, handleOpenSignup, handleOpenProMember }) => {
  return (
    <div
      className="dashboard-banner-gradience relative md:min-h-[562px] bg-gradient-to-r flex md:items-center md:justify-center w-full pb-10 md:pb-0"
      style={{
        background: "linear-gradient(96.04deg, #FF3632 10.73%, #FF8D4E 93.75%)",
      }}
    >
      <img
        src={banner}
        alt="Hero Banner"
        className="hidden md:block absolute top-0 left-0 w-full h-full object-cover"
      />
      <div className="relative max-w-7xl w-full flex flex-col md:flex-row items-center justify-center lg:justify-between gap-5 xl:gap-28 p-2 sm:p-6 md:p-14 lg:16">
        <div className="flex flex-col items-start justify-center text-white gap-2 xxs:gap-3 sm:gap-5 w-full md:max-w-lg">
          <p className="font-bold text-[28px] sm:text-4xl">
            Welcome to Network FP!
          </p>
          <p className="sm:text-xl font-medium">
            Join 11,556 Personal Finance Professionals (PFPs) who are learning
            to help their clients better and grow their Financial advisory &
            distribution practice faster.
          </p>
          <p className="text-xs sm:text-sm">
            Join for FREE or become a PROMEMBER and get access to Educational
            courses, Tools, Resources, Exchange forum and Create your own
            profile.
          </p>
          <div className="sm:mt-5 flex space-x-3 items-center">
            <StyledButton
              buttonStyle="outline-primary"
              title="Join for Free"
              customClasses="font-medium rounded-md hover:bg-transparent hover:border-white hover:text-white"
              padding="px-2 sm:px-6 py-2 sm:py-3"
              handleClick={handleOpenSignup}
            />
            <StyledButton
              buttonStyle="outline-white"
              title="Become a ProMember"
              customClasses="font-medium rounded-md"
              padding="px-2 sm:px-6 py-2 sm:py-3"
              handleClick={handleOpenProMember}
            />
          </div>
        </div>
        <div className="flex justify-center sm:justify-start md:relative bottom-18 sm:top-72 w-full sm:max-w-[525px] md:top-0">
          <video
            className="rounded-2xl xs:max-w-xs w-full sm:max-w-[525px] object-cover"
            src="https://nfp-assets.s3.ap-south-1.amazonaws.com/visitor+video.mp4"
            autoPlay={true}
            loop={true}
            muted={true}
          ></video>
        </div>
      </div>

      <p className="absolute bottom-2 w-full font-semibold text-lg text-white text-center">
        Who should join?
      </p>

      <WhoShouldJoin whoArr={whoArr} />
    </div>
  );
};

HeroSection.propTypes = {
  whoArr: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleOpenSignup: PropTypes.func.isRequired,
  handleOpenProMember: PropTypes.func.isRequired,
};

const WhoShouldJoin = ({ whoArr }) => {
  return (
    <Marquee
      style={{
        position: "absolute",
        bottom: "-64px",
        height: "4rem",
        backgroundColor: "#220C10",
      }}
    >
      {whoArr?.map((joinee, index) => (
        <div key={`who-${index}`} className="mr-3 ticker-item">
          <div className="flex items-center space-x-3">
            <div className="w-1 h-1 bg-[#FF6400] rounded-full" />
            <p className="text-white">{joinee}</p>
          </div>
        </div>
      ))}
    </Marquee>
  );
};

WhoShouldJoin.propTypes = {
  whoArr: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const NFPProfilePage = ({ handleOpenSignup }) => {
  return (
    <div className="px-5 py-4 bg-[#F9FAFB] lg:px-20 lg:py-10">
      <div className="max-w-7xl w-full mx-auto">
        <h3 className="font-bold text-2xl text-theme-black-color">
          NFP Profile Page
        </h3>

        <p className="mt-1 text-xl text-theme-black-color">
          Create a Professional Profile to showcase your expertise and enable
          clients to book appointments.
        </p>

        <div className="mt-2.5 p-4 pb-12 bg-white rounded-3xl">
          <div className="relative w-full mb-11">
            <img
              src={networkfp_profile_strip}
              alt="NFP Profile Page"
              className="h-28 rounded-xl xl:h-auto"
            />
          </div>

          <div className="relative flex flex-col justify-between items-center space-y-6 z-40 xl:flex-row xl:space-y-0">
            <div className="flex-1 pt-20 xl:pt-10 flex flex-col items-center space-y-4">
              <img
                src={networkfp_profile_image}
                alt="NFP Profile Page"
                className="absolute -top-21 w-40 h-40 rounded-full"
              />

              <div className="flex flex-col items-center">
                <p className="font-bold text-lg text-[#373333]">
                  Ananya Sharma
                </p>
                <p className="font-semibold text-[#373333]">Mumbai</p>
                <p className="text-[#373333]">MMO Financial Services Pvt Ltd</p>
              </div>

              <div className="flex flex-col space-x-0 space-y-2 items-center xl:flex-row xl:space-x-2 xl:space-y-0">
                <div className="p-2 border border-theme-orange border-opacity-25 flex items-center space-x-1 rounded-full">
                  <img
                    src={qpfp_profile_education}
                    alt="NFP Profile Education"
                    className="w-4 h-4"
                  />
                  <p className="font-medium text-xs text-theme-black-color">
                    MBA - Finance
                  </p>
                </div>

                <div className="p-2 border border-theme-orange border-opacity-25 flex items-center space-x-1 rounded-full">
                  <img
                    src={qpfp_profile_yoe}
                    alt="NFP Profile Education"
                    className="w-4 h-4"
                  />
                  <p className="font-medium text-xs text-theme-black-color">
                    9 Years of Experience
                  </p>
                </div>

                <div className="p-2 border border-theme-orange border-opacity-25 flex items-center space-x-1 rounded-full">
                  <img
                    src={qpfp_profile_oath}
                    alt="NFP Profile Education"
                    className="w-4 h-4"
                  />
                  <p className="font-medium text-xs text-theme-black-color">
                    Ethics at Heart - The Oath
                  </p>
                </div>
              </div>

              <StyledButton
                buttonStyle="outline-primary"
                title="Get Listed on NFP Directory"
                customClasses="font-medium rounded-md"
                padding="px-6 py-3"
                handleClick={() => {
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = "https://members.networkfp.com/";
                  a.target = "_blank";
                  document.body.appendChild(a);
                  a.click();
                }}
              />
            </div>

            <div className="flex-1 flex flex-col xl:flex-row xl:space-between">
              <div className="w-auto h-px bg-[#FF6400] mb-6 mr-0 xl:w-px xl:h-auto xl:mb-0 xl:mr-6" />

              <div className="mt-0 flex flex-col items-start">
                <h3 className="font-bold text-3xl text-theme-black-200">
                  Create Your NFP Profile
                </h3>

                <p className="mt-2 text-xl text-theme-black-500">
                  Create, build and use a professional profile to position
                  yourself better and showcase your expertise to clients and
                  prospects.
                </p>

                <div className="my-5 flex flex-wrap space-x-4 space-y-3">
                  <div />
                  <div className="p-2 border border-black border-opacity-10 flex items-center space-x-1 rounded-full">
                    <img
                      src={networkfp_profile_check}
                      alt="NFP Profile Education"
                      className="w-4 h-4"
                    />
                    <p className="font-medium text-sm text-theme-black-500">
                      Build a professional profile to showcase your expertise.
                    </p>
                  </div>

                  <div className="p-2 border border-black border-opacity-10 flex items-center space-x-1 rounded-full">
                    <img
                      src={networkfp_profile_check}
                      alt="NFP Profile Education"
                      className="w-4 h-4"
                    />
                    <p className="font-medium text-sm text-theme-black-500">
                      Highlight your Services and Credentials.
                    </p>
                  </div>
                  <div className="p-2 border border-black border-opacity-10 flex items-center space-x-1 rounded-full">
                    <img
                      src={networkfp_profile_check}
                      alt="NFP Profile Education"
                      className="w-4 h-4"
                    />
                    <p className="font-medium text-sm text-theme-black-500">
                      Use it as a Website.
                    </p>
                  </div>

                  <div className="p-2 border border-black border-opacity-10 flex items-center space-x-1 rounded-full">
                    <img
                      src={networkfp_profile_check}
                      alt="NFP Profile Education"
                      className="w-4 h-4"
                    />
                    <p className="font-medium text-sm text-theme-black-500">
                      Generate leads and book client appointments.
                    </p>
                  </div>

                  <div className="p-2 border border-black border-opacity-10 flex items-center space-x-1 rounded-full">
                    <img
                      src={networkfp_profile_check}
                      alt="NFP Profile Education"
                      className="w-4 h-4"
                    />
                    <p className="font-medium text-sm text-theme-black-500">
                      Establish your position as a Trusted Professional.
                    </p>
                  </div>
                </div>

                <StyledButton
                  buttonStyle="primary"
                  title="Join for Free to Create Your Profile"
                  customClasses="font-medium rounded-md"
                  padding="px-6 py-3"
                  handleClick={handleOpenSignup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NFPProfilePage.propTypes = {
  handleOpenSignup: PropTypes.func.isRequired,
};

const NFPSupportersAndPartners = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPartners = async () => {
    try {
      const response = await ContentService.fetchCategorisedPartners();
      setPartners(response.partners);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPartners();
    setLoading(true);
  }, []);

  if (loading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <div className="px-5 py-4 lg:px-20 lg:py-10">
      <h3 className="font-semibold text-xl sm:text-2xl text-black text-center">
        NFP Partners & Supporters
      </h3>
      <p className="mt-2 text-sm sm:text-base text-black text-center">
        Leading names in the industry collaborate with Network FP to help
        financial advisors enhance client service and accelerate practice
        growth.
      </p>

      <div className="my-5 grid grid-cols-2 gap-5 md:grid-cols-4 xl:grid-cols-8">
        {partners?.slice(0, 16)?.map((partner, index) => (
          <div
            key={`partner-${index}`}
            className="w-full h-18 max-w-[150px] flex justify-center items-center border border-[#EDEDED] rounded-2xl cursor-pointer"
            onClick={() => {
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = getPartnerSupporterURL(partner?.profile_slug);
              a.target = "_blank";
              document.body.appendChild(a);
              a.click();
            }}
          >
            <img
              src={partner?.logo ?? partner_placeholder}
              alt={`${partner?.name}`}
              className="w-1/3 aspect-[1/1] grayscale"
            />
          </div>
        ))}
      </div>

      <StyledButton
        buttonStyle="outline-primary"
        title="Visit Partners Directory"
        customClasses="block mx-auto mt-5 font-medium rounded-md"
        padding="px-6 py-3"
        handleClick={() => {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = "https://partners.networkfp.com/";
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
        }}
      />
    </div>
  );
};

const NFPTestimony = () => {
  const scrollableListRef = useRef();

  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTestimonials = async () => {
    try {
      const response = await EventsService.fetchTestimonials();
      setTestimonials(response.testimonials);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTestimonials();
    setLoading(true);
  }, []);
  if (loading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <div className="mb-40 xs:mb-20 px-5 py-4 bg-[#EBEDF3] md:mb-36 lg:mb-28 lg:px-20 lg:py-20 xl:mb-24">
      <h3 className="font-bold text-5xl text-center text-orange-400">
        <span>Why People Love</span>
        <img
          src={heart}
          alt="heart"
          className="inline-block mx-4 lg:w-11 lg:h-11 w-7 h-7"
        />
        <span>Network FP</span>
      </h3>

      <p className="mt-4 font-medium text-lg text-[#1e1e1e] text-center">
        NFP Team strives to deliver the best and is committed to the growth
        &amp; success Personal Finance Professionals (PFPs). Here are some
        ratings and reviews shared by NFP users, members, certificant and
        delegates.
      </p>

      <div className="mt-8 flex space-x-8 justify-center items-center">
        <img
          alt="network fp logo"
          src={networkfp_logo}
          className="h-9 lg:h-12"
        />
        <a
          href="https://www.trustpilot.com/review/networkfp.com"
          target="_blank"
          rel="noreferrer"
        >
          <img src={trustpilot} alt="heart" className="w-44" />
        </a>
        <a href="https://nfp.li/NFP-review" target="_blank" rel="noreferrer">
          <img src={googleRatings} alt="heart" className="w-44" />
        </a>
      </div>

      <div
        ref={scrollableListRef}
        className="hide-scrollbars block w-full max-w-full pb-2 overflow-x-scroll overflow-y-hidden whitespace-nowrap mt-8 mb-7"
        style={{ scrollBehavior: "smooth" }}
      >
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="w-full md:w-1/2 lg:w-1/3 pr-6 inline-block"
          >
            <div className="flex bg-white flex-col gap-3.5 items-start p-6 rounded-md">
              {_.isNull(testimonial?.rating_value) ? null : (
                <div className="flex items-center space-x-2">
                  <StarRatings rating={testimonial?.rating_value} />
                  <p className="mt-1 font-bold text-sm">
                    {testimonial?.rating_value?.toFixed(1)}
                  </p>
                </div>
              )}
              <p className="text-sm tracking-wide break-words whitespace-pre-line text-theme-black-300">
                &quot;{testimonial.review}&quot;
              </p>
              <div className="flex items-center gap-2">
                <div className="p-0.5 border-2 rounded-full border-theme-orange-400/30 w-8 h-8">
                  <ImageComponent
                    src={testimonial.profile_picture}
                    errorComponent={
                      <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full">
                        <User weight="bold" className="w-3 h-3 text-gray-100" />
                      </div>
                    }
                    alt="user"
                    className="object-cover rounded-full h-6 w-6"
                  />
                </div>
                <p className="text-xs font-medium text-theme-black-color">
                  By {testimonial.name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full mb-10">
        <CaretCircleLeft
          size={40}
          weight="fill"
          className="cursor-pointer text-[#CBD5E1]"
          onClick={() => {
            scrollableListRef.current.scrollLeft -= 400;
          }}
        />
        <CaretCircleRight
          size={40}
          weight="fill"
          className="cursor-pointer text-theme-orange-400"
          onClick={() => {
            scrollableListRef.current.scrollLeft += 400;
          }}
        />
      </div>
    </div>
  );
};

const FixedCTARibbon = ({ handleOpenSignup, handleOpenProMember }) => {
  return (
    <div
      className="fixed bottom-0 w-full px-3 md:px-5 py-2 md:py-4 flex flex-col space-x-0 space-y-1.5 md:space-y-3 bg-white z-50 lg:px-20 lg:flex-row lg:space-x-8 lg:space-y-0"
      style={{
        boxShadow: "0px -4px 9.5px 0px #5A5E7524",
      }}
    >
      <div className="flex-1 flex flex-col gap-1 lg:w-2/3">
        <p className="font-bold text-xl md:text-3xl text-theme-black-200">
          Keep Learning, Keep Growing!
        </p>
        <p className="font-medium text-sm md:text-base text-theme-black-200">
          Join 11,556+ Personal Finance Professionals Advancing Their Careers in
          India!
        </p>
      </div>

      <div className="mt-5 flex-1 flex space-x-3 justify-start items-center lg:justify-end">
        <StyledButton
          buttonStyle="primary"
          title=" Join for FREE"
          customClasses="font-medium rounded-md"
          padding="px-6 py-3"
          handleClick={handleOpenSignup}
        />
        <StyledButton
          buttonStyle="outline-primary"
          title="Become a ProMember"
          customClasses="font-medium rounded-md"
          padding="px-6 py-3"
          handleClick={handleOpenProMember}
        />
      </div>
    </div>
  );
};

FixedCTARibbon.propTypes = {
  handleOpenSignup: PropTypes.func.isRequired,
  handleOpenProMember: PropTypes.func.isRequired,
};

const VisitorScreen = (props) => {
  const navigate = useNavigate();
  const { handleOpenPopup } = useLoginModal();

  const redirectTo =
    !_.isEmpty(props.location?.state?.pathname) &&
    props.location?.state.pathname !== "/login"
      ? props.location.state.pathname + props.location.state.search
      : "/?guide_user=true";

  const handleOpenLogin = () => {
    handleOpenPopup(PopupType.LOGIN);
  };

  const handleOpenSignup = () => {
    handleOpenPopup(PopupType.SIGNUP);
  };

  const handleOpenProMember = () => {
    handleOpenPopup(PopupType.PROMEMBER);
  };

  const {
    loading: allCourseLoading,
    error: allCourseError,
    courses: allCourseData,
  } = useAllCourses(
    {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    },
    { course_type: "basic_account" },
    1,
    4
  );

  const {
    loading: proCourseLoading,
    error: proCourseError,
    courses: proCourseData,
  } = useAllCourses(
    {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    },
    { course_type: "pro_member_series" },
    1,
    4
  );

  const {
    loading: proToolsLoading,
    error: proToolsError,
    posts: proToolsData,
  } = usePosts("protool", "", 1, "", 4);

  const whoShouldJoin = [
    "Chartered Financial Analysts (CFA)",
    "Wealth Managers",
    "Mutual Fund Distributors (MFD)",
    "Registered Investment Advisors (RIA)",
    "Insurance Agents ",
    "Stock Brokers",
    "Relationship Managers (RMs)",
  ];

  return (
    <div>
      <NavBar
        handleOpenLogin={handleOpenLogin}
        handleOpenSignup={handleOpenSignup}
      />

      <HeroSection
        whoArr={whoShouldJoin}
        handleOpenSignup={handleOpenSignup}
        handleOpenProMember={handleOpenProMember}
      />

      {allCourseError ||
      allCourseLoading ||
      allCourseData.length === 0 ? null : (
        <CoursesSection
          title="NFP Courses"
          subTitle="LIVE and recorded Educational series designed for Personal Finance Professionals (PFPs)."
          background="pt-40 bg-[#F9FAFB]"
          secondaryInfo="Courses Available in NFP Basic Account (Free)"
          courses={allCourseData}
          navigationLink={"all-courses"}
          visitor={true}
        />
      )}

      {proCourseError ||
      proCourseLoading ||
      proCourseData.length === 0 ? null : (
        <CoursesSection
          title=""
          subTitle=""
          background="bg-theme-gray-300"
          secondaryInfo="Courses Available in NFP ProMembership Account"
          courses={proCourseData}
          navigationLink={"all-courses"}
          hideHeader={true}
          visitor={true}
        />
      )}

      {proToolsError || proToolsLoading || proToolsData?.length === 0 ? null : (
        <ProToolSection
          title="NFP Toolbox & Resources"
          subTitle="Ready-to-use tools, templates, and calculators to simplify and elevate your practice"
          className="px-5"
          tools={proToolsData}
        />
      )}

      <NFPProfilePage handleOpenSignup={handleOpenSignup} />

      <ChoosePlan
        title="Join for FREE and explore, or unlock premium features with ProMembership!"
        handleOpenSignup={handleOpenSignup}
        handleOpenProMember={handleOpenProMember}
      />

      {/* <JoinQPFPSection /> */}
      <QPFPSection />

      <NFPSupportersAndPartners />

      <NFPTestimony />

      <FixedCTARibbon
        handleOpenSignup={handleOpenSignup}
        handleOpenProMember={handleOpenProMember}
      />

      <LoginUserModal
        prefill_data={{ email: props?.location?.state?.email ?? "" }}
        onLoginSuccess={() => {
          setTimeout(() => {
            navigate(redirectTo ?? "/?guide_user=true");
            handleOpenPopup(PopupType.ALL);
          }, 1000);
        }}
      />
      <SignupUserModal prefill_data={{}} />
      <ProMemberRegisterModal />
    </div>
  );
};

VisitorScreen.propTypes = {
  location: PropTypes.object,
};

export default VisitorScreen;
