import PropTypes from "prop-types";
import { PlayCircle, DownloadSimple } from "@phosphor-icons/react";
import StyledButton from "./StyledButton";
import { ContentBox } from "../../stories/CCContent";
import { navigate } from "@reach/router";
import { User } from "../../store";
import { CustomToast } from "../../stories/Toast";
const ProToolCard = ({
  title,
  subTitle,
  category,
  fileUrl,
  fileType,
  watchLink,
  downloadLink,
  attachmentFileType,
  isProMember,
  isProMemberVisible,
  previewThumbnail,
}) => {
  const encodedUrl = encodeURIComponent(watchLink);
  const encodedDownloadUrl = encodeURIComponent(downloadLink);
  const path = `/toolbox/protool-video-preview/${encodedUrl}/${encodedDownloadUrl}`;
  const isLoggedIn = !!User.getToken();

  return (
    <div className="flex flex-col justify-between gap-4 min-h-[377px] xxs:min-w-[300px] xxs:max-w-[308px] border border-black-opacity-10 rounded-xl p-4">
      <div className="flex flex-col justify-between gap-4">
        <p className="line-clamp-1 w-11/12 text-xl font-bold tracking-tight leading-5">
          {title}
        </p>
        <div className="border-b border-black-opacity-10"></div>
        <ContentBox
          type={previewThumbnail ? "image" : fileType}
          src={previewThumbnail ? previewThumbnail : fileUrl}
          newCard={true}
        />
        <div className="flex items-center justify-between">
          {category ? (
            <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
              {category}
            </p>
          ) : (
            <p></p>
          )}
          <div className="flex items-center justify-center gap-1 text-theme-black-300 text-xs">
            <p>File Type :</p>
            <p className="first-letter:capitalize">{attachmentFileType}</p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-1">
          <p className="line-clamp-2 text-black-opacity-60">{subTitle}</p>
        </div>
      </div>
      {isLoggedIn && (
        <div className="flex justify-between gap-3">
          {watchLink && (
            <StyledButton
              padding={"px-2.5 py-3"}
              handleClick={() => {
                !isProMember && isProMemberVisible
                  ? CustomToast.error("You are not a Pro Member")
                  : navigate(path);
              }}
              title={"Watch Demo"}
              buttonStyle="outline-primary"
              icon={<PlayCircle size={20} className="hover:text-white" />}
            />
          )}
          {downloadLink && (
            <StyledButton
              padding={"px-2.5 py-3"}
              handleClick={() => {
                !isProMember && isProMemberVisible
                  ? CustomToast.error("You are not a Pro Member")
                  : window.open(downloadLink, "_blank");
              }}
              title={"Download"}
              buttonStyle="primary"
              icon={<DownloadSimple size={20} />}
            />
          )}
        </div>
      )}
    </div>
  );
};

ProToolCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fileUrl: PropTypes.string,
  category: PropTypes.string,
  fileType: PropTypes.string,
  attachmentFileType: PropTypes.string,
  watchLink: PropTypes.string,
  downloadLink: PropTypes.string,
  isProMember: PropTypes.bool,
  isProMemberVisible: PropTypes.bool,
  previewThumbnail: PropTypes.string,
};

export default ProToolCard;
