/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import RenewalPaymentScreen from "./RenewalPaymentScreen";

const MembershipRenewalForm = ({
  className = "",
  isNewUser = false,
  currentMembershipDetails = {},
  publicProfileData = {},
  prefillData = {},
  userTempTokenRef = undefined,
  options = {},
  entityProps = {},
  course_id,
  onUpdate,
  loading = true,
  hasRSVP = false,
}) => {
  return (
    <div className={className}>
      <div className="flex flex-col items-start w-full gap-4 lg:py-4 lg:bg-white">
        <div className="w-full p-4 pt-0 bg-white rounded shadow-sm lg:rounded-none lg:shadow-none">
          <RenewalPaymentScreen
            isNewUser={isNewUser}
            currentMembershipDetails={{
              ...currentMembershipDetails,
              payment: {},
            }}
            publicProfileData={publicProfileData}
            options={options}
            userTempTokenRef={userTempTokenRef}
            entityProps={entityProps}
            loading={loading}
            prefillData={prefillData}
            course_id={course_id}
            onUpdate={onUpdate}
          />
        </div>
      </div>
    </div>
  );
};

MembershipRenewalForm.propTypes = {
  className: PropTypes.string,
  course_id: PropTypes.any,
  currentMembershipDetails: PropTypes.object,
  entityProps: PropTypes.object,
  hasRSVP: PropTypes.bool,
  isNewUser: PropTypes.bool,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  prefillData: PropTypes.object,
  publicProfileData: PropTypes.object,
  userTempTokenRef: PropTypes.any,
};

export default MembershipRenewalForm;
