import PropTypes from "prop-types";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

const Pagination = ({
  totalEntries,
  entriesPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      // Show all pages if total pages are 5 or less
      for (let i = 1; i <= totalPages; i++) {
        pages.push(renderPageButton(i));
      }
    } else {
      // Always show first page
      pages.push(renderPageButton(1));

      if (currentPage > 3) {
        pages.push(
          <span key="ellipsis-start" className="px-3 py-1 pt-4 text-gray-700">
            ...
          </span>
        );
      }

      // Calculate middle range
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(renderPageButton(i));
      }

      if (currentPage < totalPages - 2) {
        pages.push(
          <span key="ellipsis-end" className="px-3 py-1 pt-4 text-gray-700">
            ...
          </span>
        );
      }

      // Always show last page
      pages.push(renderPageButton(totalPages));
    }

    return pages;
  };

  // Helper function to render page buttons
  const renderPageButton = (pageNumber) => (
    <button
      key={pageNumber}
      onClick={() => handleClick(pageNumber)}
      className={`px-3 py-1 ${
        currentPage === pageNumber
          ? "pt-4 px-3 border-t-2 border-theme-orange"
          : "pt-4 px-3 text-gray-700"
      }`}
    >
      {pageNumber}
    </button>
  );

  return (
    <div className="flex justify-between">
      <button
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        className={`flex items-center text-theme-black-300 border-t pt-4 gap-3 px-3${
          currentPage === 1 ? "text-gray-300 border-gray-300" : "text-gray-700"
        }`}
      >
        <ArrowLeft size={20} color="#525252" weight={"bold"} />
        <p>Previous</p>
      </button>
      <div className="flex items-start justify-center h-full w-full border-t text-xl">
        {renderPageNumbers()}
      </div>
      <button
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`flex items-center text-theme-black-300 border-t pt-4 gap-3 px-3${
          currentPage === totalPages ? "text-gray-300" : "text-gray-700"
        }`}
      >
        <p>Next</p>
        <ArrowRight size={20} color="#525252" weight={"bold"} />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalEntries: PropTypes.number,
  entriesPerPage: PropTypes.number,
};

export default Pagination;
