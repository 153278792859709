import { useProfile } from "../../context/userProvider";
import CircularProgressBar from "./CircularProgress";
import StyledButton from "../CommonComponents/StyledButton";
import { getDirectoryMemberURL } from "../../util/getURLs";
import CustomLink from "../../stories/Link";
import { navigate } from "@reach/router";
import { UserPlanTag } from "../../Constants";
import ShareOptionPopup from "../ShareOptionPopup";
const ProfileDetailsCard = () => {
  const { userDetails } = useProfile();
  const profileLink = getDirectoryMemberURL(userDetails?.profile_slug);
  return (
    <div className="absolute md:relative top-[85%] xxs:min-w-[400px]" id="tour">
      <div className="flex flex-col items-center justify-center bg-white border border-[#DFDFDF] p-3 sm:p-6 rounded-2xl w-11/12 xs:w-full max-w-[420px] gap-3 md:gap-6">
        <div className="flex items-center justify-between w-full">
          <CircularProgressBar
            imageSrc={userDetails.profile_picture?.signed_url ?? ""}
            percentage={userDetails?.profile_complete_percentage ?? 0}
          />
          {userDetails.is_pro_member ? (
            ["approved", "expires_soon", "provisional"].includes(
              userDetails.pm_signup_status
            ) && <UserPlanTag plan={"pro_member"} />
          ) : (
            <UserPlanTag />
          )}
        </div>
        <div className="flex flex-col justify-start items-start w-full gap-1 ">
          <p>{userDetails?.display_name ?? userDetails?.first_name ?? ""} </p>
          <p>{userDetails?.firm_name ?? ""}</p>
        </div>
        {userDetails?.member_since && (
          <div className="flex flex-col justify-start items-start w-full">
            <p>Member Since </p>
            <p>{userDetails?.member_since ?? ""}</p>
          </div>
        )}
        <div className="flex items-center xs:justify-between flex-wrap xs:flex-nowrap gap-1 xs:gap-3 w-full">
          {userDetails?.profile_slug && (
            <div className="min-w-32 max-w-24 xs:max-w-full w-full">
              <CustomLink
                textColor="text-blue-600"
                activeTextColor="text-blue-600"
                showUnderLine={false}
                underLineOnlyOnHover={false}
                fontSize="sm"
                target="_blank"
                href={profileLink}
                className="font-normal leading-5 tracking-tight h-fit"
              >
                <StyledButton
                  textSize={"text-xs px-3"}
                  handleClick={() => {}}
                  title={"View Profile"}
                />
              </CustomLink>
            </div>
          )}
          <div className="min-w-32 max-w-24 xs:max-w-full w-full">
            <StyledButton
              textSize={"text-xs px-3"}
              buttonStyle="outline-primary"
              handleClick={() => {
                navigate("/public-profile");
              }}
              title={"Edit Profile"}
            />
          </div>
          <ShareOptionPopup
            className="ml-5"
            name={userDetails?.display_name ?? userDetails?.first_name}
            profileLink={profileLink}
          />
        </div>
      </div>
    </div>
  );
};
export default ProfileDetailsCard;
