import Breadcrumb from "../../stories/Breadcrumb";
import CourseCard from "../CommonComponents/CourseCard";
import Pagination from "../CommonComponents/Pagination";
import { useState } from "react";
import { useMyCourses } from "../../services/CourseDetails";
import Loader from "../Shimmer/Loader";
import NoDataFound from "../CommonComponents/NoDataFound";
import AppSkeleton from "../AppSkeleton";

const MyCourses = () => {
  const entriesPerPage = 12;
  const breadCrumbData = [
    { name: "Dashboard", to: "/" },
    { name: "My Courses", to: "/my-programs" },
  ];
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading: courseLoading,
    error: courseError,
    courses: courseData,
    pagination,
  } = useMyCourses({}, currentPage, entriesPerPage);

  if (courseLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <AppSkeleton>
      <div
        className={`flex justify-center w-full py-10 px-5 bg-theme-gray-25 min-h-screen h-full bg-start bg-no-repeat bg-contain bg-my_courses_bg`}
      >
        <div className="flex flex-col gap-6 max-w-7xl w-full">
          <div className="flex flex-col sm:flex-row gap-5 items-center max-w-7xl w-full">
            <div className="flex flex-col gap-1.5 justify-center h-fit w-full">
              <p className="text-2xl font-bold">My Courses and Progress</p>
              <Breadcrumb pages={breadCrumbData} type="chevron" />
            </div>
          </div>
          <div className="flex flex-col gap-1 text-theme-black-500">
            <p className="text-xl font-medium">My Courses and Progress</p>
            <div className="flex flex-col gap-0.5">
              <p className="text-sm">
                Here are the all the courses you have started, currently
                pursuing and completed.
              </p>
              <p className="text-sm">
                Note: Please click on the “Start Course” button to unlock and
                begin the course.
              </p>
            </div>
          </div>

          {courseError || courseData?.length === 0 ? (
            <NoDataFound subTitle={""} />
          ) : (
            <>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center sm:justify-between gap-6">
                {courseData?.map((course, index) => (
                  <CourseCard
                    id={course?.id}
                    key={"my-programs" + index}
                    title={course?.title}
                    subTitle={course?.description}
                    category={course?.category?.value}
                    image={course?.course_logo}
                    status={
                      course?.course_completion_percentage === 100
                        ? "complete"
                        : "inProgress"
                    }
                    progress={course?.course_completion_percentage ?? 0}
                    rating={course?.average_rating}
                    lastActivity={course?.last_viewed_content?.last_seen}
                    courseType={course?.course_type}
                    courseSection="my-programs"
                    isMyCourseCard={true}
                  />
                ))}
              </div>
              <Pagination
                totalEntries={pagination?.total_count ?? 0}
                entriesPerPage={entriesPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </div>
      </div>
    </AppSkeleton>
  );
};

export default MyCourses;
