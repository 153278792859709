/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Star } from "@phosphor-icons/react";
import { navigate } from "@reach/router";
import ImageComponent from "../../stories/ImageComponent";
import StyledButton from "../CommonComponents/StyledButton";
import { useProfile } from "../../context/userProvider";
import { User } from "../../store";
import { UserPlanTag } from "../../Constants";
import LinearProgressBar from "../CommonComponents/LinearProgressBar";

const AllCourseCard = ({
  id,
  title,
  subTitle,
  category,
  image,
  rating,
  status,
  duration,
  level,
  courseType,
  hasRegistered = false,
  pricing,
  isLoggedIn = false,
  hasStarted = false,
  progress,
  lastActivity,
}) => {
  const [navigationLink, setNavigationLink] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [hoverPosition, setHoverPosition] = useState("left-[105%]");
  const cardRef = useRef(null);
  const { userDetails } = useProfile();

  const CourseTags = [
    "free_training",
    "community",
    "pro_member",
    "pro_member_series",
    "qpfp_member",
  ];

  const handleMouseEnter = (e) => {
    const cardRect = cardRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const spaceOnRight = viewportWidth - cardRect.right;
    const spaceOnLeft = cardRect.left;

    // Calculate if there's enough space on the right
    if (spaceOnRight >= cardRect.width) {
      setHoverPosition("left-[105%]");
    } else if (spaceOnLeft >= cardRect.width) {
      setHoverPosition("right-[105%]");
    } else {
      // If there's not enough space on either side, position it where there's more space
      setHoverPosition(
        spaceOnRight > spaceOnLeft ? "left-[105%]" : "right-[105%]"
      );
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getCTATitle = () => {
    if (progress === 100) {
      return "Completed";
    }
    if (hasStarted || hasRegistered) {
      return "Continue Course";
    }
    if (
      courseType === "free_training" ||
      courseType === "new_user" ||
      courseType === "demo"
    ) {
      if (User.getToken()) {
        return "Start Course";
      }
    } else if (courseType?.includes("pro_member")) {
      if (
        userDetails.is_pro_member &&
        ["approved", "expires_soon", "provisional"].includes(
          userDetails.pm_signup_status
        )
      ) {
        return "Start Course";
      }
    }
    return "Know More";
  };
  const NavigationTriangle = ({ direction }) => (
    <div
      className={`absolute top-1/2 -translate-y-1/2 z-10 ${
        direction === "left" ? "-left-2.5" : "-right-2.5"
      } w-0 h-0 border-y-[8px] border-y-transparent ${
        direction === "left"
          ? "border-r-[10px] border-r-white"
          : "border-l-[10px] border-l-white"
      } transition-opacity duration-300`}
    />
  );

  useEffect(() => {
    if (courseType === "qpfp") {
      if (hasRegistered) {
        setNavigationLink("/programs/" + id);
      } else {
        setNavigationLink(`/members/qpfp/register?course_id=${id}`);
      }
    } else {
      setNavigationLink("/programs/" + id);
    }
  }, [id, courseType, hasRegistered]);

  return (
    <div
      className="relative cursor-pointer"
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <>
          <div
            className={`hidden lg:flex absolute w-full xxs:max-w-[308px] min-h-[377px] top-1/2 -translate-y-1/2 ${hoverPosition} 
          transition-all duration-300 ease-in-out z-30`}
          >
            <NavigationTriangle
              direction={hoverPosition === "left-[105%]" ? "left" : "right"}
            />
            <div className="absolute inset-0 flex items-center justify-center overflow-y-scroll rounded-xl border border-theme-gray-150 bg-white shadow-2xl no-scrollbar">
              <div className="flex flex-col gap-2 px-3 py-5 text-theme-black-300">
                <h3 className="text-xl font-bold text-theme-black-500">
                  {title}
                </h3>
                <div className="flex items-center justify-between">
                  {category ? (
                    <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
                      {category}
                    </p>
                  ) : (
                    <div className="h-6" />
                  )}
                  {rating && (
                    <div className="flex items-center justify-center gap-1">
                      <Star weight="fill" color="#FFCC47" />
                      <p className="color-theme-black-color font-bold text-sm">
                        {rating}
                      </p>
                    </div>
                  )}
                </div>
                <p className="text-sm">{subTitle}</p>
                <div className="space-y-0.5">
                  <p className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-theme-orange"></div>{" "}
                    Course duration: {duration}
                  </p>
                  <p className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-theme-orange"></div>{" "}
                    Level: {level}
                  </p>
                  {pricing > 0 && (
                    <p className="flex items-center gap-2">
                      <div className="h-1.5 w-1.5 rounded-full bg-theme-orange"></div>{" "}
                      Price: ₹{pricing}
                    </p>
                  )}
                </div>
                <StyledButton
                  buttonStyle="outline-primary"
                  handleClick={() => navigate(navigationLink)}
                  padding="px-3 py-2"
                  title={getCTATitle()}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col gap-4 min-h-[377px] h-fit w-full xxs:max-w-[308px] border border-black-opacity-10 rounded-xl p-4 bg-white">
        <div className="relative">
          <ImageComponent
            alt="Course Banner"
            src={image}
            errorComponent={
              <div className="border border-black-opacity-10 flex items-center justify-center rounded-md w-full xxs:max-w-[276px] h-[156px]" />
            }
            className="border border-black-opacity-10 object-cover rounded-md w-full xxs:max-w-[276px] h-[156px]"
          />
        </div>
        <div className="flex items-center justify-between">
          {category ? (
            <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
              {category}
            </p>
          ) : (
            <div className="h-6" />
          )}
          {rating && (
            <div className="flex items-center justify-center gap-1">
              <Star weight="fill" color="#FFCC47" />
              <p className="color-theme-black-color font-bold text-sm">
                {rating}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col items-start justify-start gap-1 h-20">
          <p className="line-clamp-1 w-11/12 text-xl font-bold tracking-tight">
            {title}
          </p>
          <p className="w-full line-clamp-2">{subTitle}</p>
        </div>

        {hasStarted ? (
          <LinearProgressBar lastActivity={lastActivity} progress={progress} />
        ) : (
          <div className="flex items-center justify-between text-sm mt-3">
            <p className="text-theme-gray-500">{duration}</p>
            <p className="text-theme-black-color">
              {level === "beginner"
                ? "Beginner"
                : level === "advance"
                ? "Advanced"
                : level === "intermediate"
                ? "Intermediate"
                : ""}
            </p>
          </div>
        )}

        {pricing > 0 && !(hasRegistered || hasStarted) ? (
          <div className="flex justify-between">
            <div className="flex flex-col w-full max-w-52">
              {courseType === "event" || !CourseTags.includes(courseType) ? (
                <p className="text-lg font-bold text-theme-orange">
                  {pricing && `₹ ${pricing}`}
                </p>
              ) : (
                CourseTags.includes(courseType) && (
                  <p className="text-xs flex items-center gap-1 font-bold text-theme-orange">
                    Included with{" "}
                    <UserPlanTag plan={courseType} isSmall={true} />
                  </p>
                )
              )}
              {courseType === "event" ? (
                <p className="text-[10px] text-theme-black-300">*{status}</p>
              ) : (
                CourseTags.includes(courseType) && (
                  <p className="text-[10px] text-theme-black-300">
                    Free worth {pricing && `₹ ${pricing}`}
                  </p>
                )
              )}
            </div>
            <StyledButton
              buttonStyle="outline-primary"
              handleClick={() => navigate(navigationLink)}
              padding="px-3 py-2"
              customClasses="w-40 rounded-md"
              title={getCTATitle()}
            />
          </div>
        ) : (
          <div className="flex justify-between">
            <StyledButton
              buttonStyle="outline-primary"
              handleClick={() => navigate(navigationLink)}
              padding="px-3 py-2"
              title={getCTATitle()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

AllCourseCard.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  status: PropTypes.string,
  category: PropTypes.string,
  rating: PropTypes.number,
  duration: PropTypes.string,
  level: PropTypes.oneOf(["beginner", "intermediate", "advanced"]),
  courseType: PropTypes.string,
  hasRegistered: PropTypes.bool,
  hasStarted: PropTypes.bool,
  pricing: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  progress: PropTypes.any,
  lastActivity: PropTypes.any,
};

export default AllCourseCard;
