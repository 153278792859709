import PropTypes from "prop-types";
import StyledButton from "../CommonComponents/StyledButton";
// import LinearProgressBar from "../CommonComponents/LinearProgressBar";
import ImageComponent from "../../stories/ImageComponent";
import { navigate } from "@reach/router";

const LandScapeCoursesCard = ({
  title,
  subTitle,
  status,
  lastActivity,
  progress,
  thumbnail,
  courseId,
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-4 w-full max-w-[714px] border border-black-opacity-10 rounded-xl p-5 bg-white">
      <ImageComponent
        alt="profile-icon"
        src={thumbnail ?? ""}
        errorComponent={
          <div className="w-full md:max-w-[370px] border border-black-opacity-10 rounded-md h-52 object-cover"></div>
        }
        className="h-52 md:max-w-[370px] border border-black-opacity-10 rounded-md object-cover object-center"
      />
      <div className="flex flex-col w-full md:max-w-[281px] gap-2.5">
        <p className="text-xl font-bold line-clamp-1">{title}</p>
        <p className="line-clamp-4 text-theme-black-low-opacity-200">
          {subTitle}
        </p>
        {status === "inProgress" ? (
          <div className="mt-5">
            {/* <LinearProgressBar
              lastActivity={lastActivity}
              progress={progress}
            /> */}
            <StyledButton
              handleClick={() => {
                navigate(`/programs/${courseId}`);
              }}
              title={"Continue Course"}
            />
          </div>
        ) : (
          <div className="mt-5">
            <StyledButton
              handleClick={() => {
                navigate(`/programs/${courseId}`);
              }}
              title={"Start Course"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
LandScapeCoursesCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  status: PropTypes.string,
  lastActivity: PropTypes.any,
  progress: PropTypes.number,
  thumbnail: PropTypes.string,
  courseId: PropTypes.number,
};

export default LandScapeCoursesCard;
