/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { Star } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import RatingsCard from "./RatingCard";
import StyledButton from "../CommonComponents/StyledButton";
import { CourseService } from "../../services/CourseService";
import { useProfile } from "../../context/userProvider";
import mixpanel from "mixpanel-browser";
import { CustomToast } from "../../stories/Toast";

const RatingsAndReviews = ({
  reviewCount,
  averageRating,
  contentId,
  hasRegistered,
  hasReviewed,
}) => {
  const ReviewsPerPage = 5;
  const { userDetails } = useProfile();
  const stars = Array(5).fill(0);
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [review, setReview] = useState("");

  const handleClickStar = (value) => {
    setRating(value);
  };

  const fetchReviews = async () => {
    const res = await CourseService.fetchReviewsForSession({
      entity_id: contentId,
      reviewer_id: userDetails.id,
      entity_type: "Course",
      per_page: ReviewsPerPage,
      page: currentPage,
    });
    setReviews([...reviews, ...res?.ratings]);
    setPagination(res?.pagination ?? {});
  };

  const onPostReview = async ({ review, rating_value }) => {
    if (!review) {
      CustomToast.error("Review cannot be empty");
      return;
    }
    if (rating_value === 0) {
      CustomToast.error("Rating cannot be empty");
      return;
    }
    console.log({
      review: review,
      rating_value: rating_value,
      entity_id: contentId,
      user_id: userDetails.id,
    });
    const response = await CourseService.postReviewsForSession({
      review: review,
      rating_value: rating_value,
      entity_id: contentId,
      entity_type: "Course",
      user_id: userDetails.id,
    });
    setReviews([response.rating, ...reviews]);
    setRating(0);
    setReview("");
    window.location.reload();

    mixpanel.track("User Rating on Course Content", {
      distinct_id: userDetails.email,
      "Course ID": contentId,
      Ratings: rating_value,
      Review: review,
    });
  };

  useEffect(() => {
    fetchReviews();
  }, [currentPage]);

  return (
    <div className="flex flex-col gap-2.5 sm:gap-5 w-full max-w-[830px] bg-white border border-black-opacity-10 p-4">
      {(averageRating > 0 || reviewCount > 0) && (
        <div className="flex flex-wrap gap-x-2 items-center xxs:text-xl md:text-2xl font-bold border-b pb-6">
          {averageRating > 0 && (
            <>
              <Star
                weight="fill"
                color="#FFCC47"
                className="w-4 sm:w-8 h-4 sm:h-8"
              />
              <h2>{averageRating} course rating</h2>
            </>
          )}
          {reviewCount > 0 && (
            <>
              {averageRating > 0 && (
                <div className="w-1.5 sm:w-2 h-1.5 sm:h-2 rounded-full bg-theme-gray-150 sm:mx-1"></div>
              )}
              <h2>{reviewCount} ratings</h2>
            </>
          )}
        </div>
      )}
      {hasRegistered && !hasReviewed && (
        <>
          <p>Attended the course?</p>
          <p>Rate the course</p>
          <div className="flex items-center gap-3">
            {stars.map((_, index) => {
              return (
                <Star
                  key={`rating-${index + 1}`}
                  size={60}
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                  color={"#FFCC47"}
                  onClick={() => handleClickStar(index + 1)}
                  weight={rating > index ? "fill" : "light"}
                  className="cursor-pointer"
                />
              );
            })}
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-lg font-bold">Review the course</p>
            <div className="flex flex-col sm:flex-row gap-3 sm:border items-center rounded-lg sm:bg-theme-gray-50">
              <textarea
                name="review"
                label="Write a review"
                placeholder="Share your feedback here"
                rows={4}
                id="review"
                type="text"
                maxLength={1024}
                value={review}
                className="outline-none focus:outline-none focus:border-none border-transparent focus:border-transparent focus:ring-0 w-full h-16 bg-theme-gray-50 placeholder:text- flex items-center"
                onChange={(e) => setReview(e.target.value)}
              />
              <div className="w-full sm:max-w-[100px] mr-2">
                <StyledButton
                  title={"Submit"}
                  className={"sm:w-[100px] h-10"}
                  padding={"px-4 py-2"}
                  handleClick={() => {
                    onPostReview({
                      review: review,
                      rating_value: rating,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {reviews?.length > 0 &&
        reviews?.map((rating, index) => (
          <RatingsCard
            key={rating?.id}
            id={rating?.id}
            profileSlug={rating?.profile_slug ?? ""}
            name={rating?.reviewer_name ?? ""}
            userImage={rating?.reviewer_picture ?? ""}
            time={rating?.created_at}
            content={rating?.review ?? ""}
            rating={rating?.rating_value ?? ""}
          />
        ))}
      {!pagination?.last_page && reviews?.length > 0 && (
        <p
          className="text-theme-red-600 font-medium cursor-pointer w-fit"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          SEE MORE
        </p>
      )}
      {hasReviewed && "You have already reviewed this course"}
    </div>
  );
};

RatingsAndReviews.propTypes = {
  reviewCount: PropTypes.number,
  averageRating: PropTypes.any,
  contentId: PropTypes.number,
  hasRegistered: PropTypes.bool,
  hasReviewed: PropTypes.bool,
};

export default RatingsAndReviews;
