import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { confettiAnimation } from "../../util/confettiAnimation.js";
import { Actions } from "../Events/NationalEventRegistrationThankyouScreen.js";
import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import promember_logo from "../../assets/images/promember_logo.png";
import visitor_courses from "../../assets/svgs/visitor_courses.svg";
import visitor_resources from "../../assets/svgs/visitor_resources.svg";
import visitor_live_sessions from "../../assets/svgs/visitor_live_sessions.svg";
import visitor_exchange_forum from "../../assets/svgs/visitor_exchange_forum.svg";
import visitor_client_connect from "../../assets/svgs/visitor_client_connect.svg";
import visitor_basic_profile from "../../assets/svgs/visitor_profile.svg";
import visitor_public_directory from "../../assets/svgs/visitor_public_directory.svg";
import visitor_discounts from "../../assets/svgs/visitor_discounts.svg";

const NFPFeature = ({ index, highlight, text }) => {
  const getImage = () => {
    switch (index) {
      case 0:
        return visitor_courses;
      case 1:
        return visitor_resources;
      case 2:
        return visitor_live_sessions;
      case 3:
        return visitor_exchange_forum;
      case 4:
        return visitor_client_connect;
      case 5:
        return visitor_basic_profile;
      case 6:
        return visitor_public_directory;
      case 7:
        return visitor_discounts;
      default:
        return visitor_courses;
    }
  };

  return (
    <div className="flex space-x-2 items-center">
      <img src={getImage()} alt="NFP Basic Account Feature" />
      <p>
        <span className="font-bold">{highlight}</span> {text}
      </p>
    </div>
  );
};

NFPFeature.propTypes = {
  index: PropTypes.number.isRequired,
  highlight: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const ProMemberRenewalThankYou = (props) => {
  const { first_name } = props?.location?.state;
  const confettiCanvas = useRef();

  const features = [
    {
      highlight: "20",
      text: "NFP Courses",
    },
    {
      highlight: "20",
      text: "ProTools and Resources",
    },
    {
      highlight: "Wednesday",
      text: "Live Sessions",
    },
    {
      highlight: "View & Ask / Answer",
      text: "Exchange Forum",
    },
    {
      highlight: "100 Images",
      text: "Client Connect",
    },
    {
      highlight: "Complete",
      text: "PROfile",
    },
    {
      highlight: "Public Directory Listing",
      text: "",
    },
    {
      highlight: "Discounts on QPFP / Events",
      text: "",
    },
  ];

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <>
      <div className="flex flex-row items-center justify-between px-5 py-4 lg:px-20 bg-white">
        <div>
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-9 lg:h-12"
          />
        </div>
      </div>
      <div className="min-h-[calc(100vh-115px)] flex bg-theme-gray-200">
        <div className="relative flex flex-col items-center w-full max-w-5xl p-8 mx-auto my-12 bg-white z-[60]">
          <img
            src={promember_logo}
            alt="Static img"
            className="mb-8 w-52 h-20"
          />
          <p className="font-extrabold text-2xl text-theme-orange">
            Hello {first_name}
          </p>
          <h1 className="mt-4 font-extrabold text-4xl text-theme-black-500 text-center">
            Congratulations on becoming a NFP ProMember!
          </h1>
          <p className="mt-2 w-full text-theme-black-300 text-center md:w-2/4">
            The lifelong journey of learning and growing starts now!
          </p>

          <div className="mt-10 mb-4 p-6 w-full bg-[#f5f7fb]">
            <p className="font-semibold text-theme-black-500">
              Make the best of your ProMembership
            </p>

            <div className="mt-6 grid justify-between grid-cols-1 gap-y-6 md:grid-cols-2">
              {features?.map((feature, index) => (
                <NFPFeature
                  key={`nfp-feature-${index}`}
                  index={index}
                  highlight={feature?.highlight}
                  text={feature?.text}
                />
              ))}
            </div>
          </div>

          <Actions
            label="Go to Your Dashboard"
            description="Login, explore & access your NFP programs and benefits here"
            link="/"
            btnLabel="Dashboard"
          />

          <canvas
            ref={confettiCanvas}
            className="absolute z-50 w-full h-full"
          />
        </div>
      </div>
    </>
  );
};

ProMemberRenewalThankYou.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default ProMemberRenewalThankYou;
