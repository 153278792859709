import { Formik, Field } from "formik";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import {
  CourseDurationFilters,
  CourseLevelFilters,
  CourseTypeFilters,
  QPFPFilter,
} from "../../Constants";
import { useState } from "react";
import StyledButton from "./StyledButton";

const AllCoursesFilter = ({
  handleFilterChange,
  filters,
  courseCategoryFilters,
  handleApplyFilters,
  handleClearAllFilters,
}) => {
  return (
    <div className="flex flex-col gap-4 w-full md:w-[270px] h-fit bg-white rounded-[10px] p-3 z-10">
      <div className="flex items-center justify-between w-full">
        <p className="text-theme-black-500 text-2xl font-semibold">
          Filter Courses
        </p>
        <p
          className="text-theme-blue-200 font-bold cursor-pointer"
          onClick={handleClearAllFilters}
        >
          Clear All
        </p>
      </div>

      <Formik
        enableReinitialize
        initialValues={filters}
        onSubmit={(values) => {
          handleFilterChange(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onChange={handleSubmit} onSubmit={(e) => e.preventDefault()}>
            {CourseTypeFilters && (
              <CourseFilter
                title={"Course Type"}
                filters={CourseTypeFilters}
                name="course_type"
                setFieldValue={setFieldValue}
                values={values?.course_type}
              />
            )}
            <div className="border-theme-gray border-b-[1px] mb-3 "></div>
            {courseCategoryFilters && (
              <CourseFilter
                title={"Course Category"}
                filters={courseCategoryFilters}
                name="category_id"
                setFieldValue={setFieldValue}
                values={values?.category_id}
              />
            )}
            <div className="border-theme-gray border-b-[0.5px] mb-3 "></div>

            {CourseDurationFilters && (
              <CourseFilter
                title={"Course Duration"}
                filters={CourseDurationFilters}
                name="duration_filter"
                setFieldValue={setFieldValue}
                values={values?.duration_filter}
              />
            )}
            <div className="border-theme-gray border-b-[1px] mb-3 "></div>

            {CourseLevelFilters && (
              <CourseFilter
                title={"Course Level"}
                filters={CourseLevelFilters}
                name="course_level"
                setFieldValue={setFieldValue}
                values={values?.course_level}
              />
            )}
            <div className="border-theme-gray border-b-[1px] mb-3 "></div>

            {QPFPFilter && (
              <CourseFilter
                title={""}
                filters={QPFPFilter}
                name="course_type"
                setFieldValue={setFieldValue}
                values={values?.course_type}
              />
            )}
          </form>
        )}
      </Formik>
      {handleApplyFilters && (
        <StyledButton
          buttonStyle="primary"
          title="Done"
          handleClick={handleApplyFilters} // Apply filters
        />
      )}
    </div>
  );
};

const CourseFilter = ({ title, filters, name, setFieldValue, values }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCheck = (filterValue) => {
    const updatedValues = values?.includes(filterValue)
      ? values?.filter((val) => val !== filterValue)
      : [...values, filterValue];
    setFieldValue(name, updatedValues);
  };

  return (
    <div className="flex flex-col gap-3 w-full pb-4">
      {title && (
        <div className="flex items-center justify-between">
          <p className="text-theme-black-500 font-bold">{title}</p>
          {isOpen ? (
            <CaretUp
              size={24}
              weight="fill"
              onClick={() => setIsOpen(!isOpen)}
            />
          ) : (
            <CaretDown
              size={24}
              weight="fill"
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
        </div>
      )}
      {isOpen &&
        filters?.map((item, index) => (
          <div key={`filter-${index}`} className="flex items-center gap-2">
            <Field
              type="checkbox"
              name={name}
              value={item.id}
              checked={values?.includes(item.id)}
              onChange={() => handleCheck(item.id)}
            />
            <span className="font-semibold">{item.value}</span>
          </div>
        ))}
    </div>
  );
};

CourseFilter.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

AllCoursesFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  courseCategoryFilters: PropTypes.array.isRequired,
  handleApplyFilters: PropTypes.func,
  handleClearAllFilters: PropTypes.func,
};

export default AllCoursesFilter;
