/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import StyledButton from "../CommonComponents/StyledButton";
import ProToolCard from "../CommonComponents/ProToolCard";
import classNames from "classnames";
import ResponsiveCardEncloser from "./ResponsiveCardEncloser";
import { useNavigate } from "@reach/router";
import { useProfile } from "../../context/userProvider";

const ProToolSection = ({
  title,
  subTitle,
  background,
  className,
  viewAllButtonClass,
  viewAllButtonSize,
  tools,
}) => {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  return (
    <div
      className={classNames(
        `flex items-center justify-center w-full py-10 ${background}`,
        className
      )}
    >
      <div className="flex flex-col gap-5 max-w-7xl w-full" id={"nfp-toolbox"}>
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-2 w-3/4">
            <p className="text-xl md:text-2xl font-bold">{title ?? ""}</p>
            <p className="md:text-xl">{subTitle ?? ""}</p>
          </div>
          <div className="">
            <StyledButton
              title={"View All"}
              handleClick={() => {
                navigate("/toolbox");
              }}
              buttonStyle={viewAllButtonClass}
              textSize={viewAllButtonSize}
            />
          </div>
        </div>
        <div
          className={classNames(
            "xl:hidden",
            tools?.length < 2
              ? "xxs:hidden"
              : tools?.length < 3
              ? "md:hidden"
              : "xl:hidden"
          )}
        >
          <ResponsiveCardEncloser>
            {tools.map((proTool, index) => (
              <ProToolCard
                key={`protools-${index}`}
                title={proTool?.title}
                subTitle={proTool?.text_content}
                fileType={proTool?.post_content?.file_type ?? "File"}
                category={proTool?.category?.value}
                downloadLink={proTool?.post_content?.url}
                fileUrl={proTool?.post_content?.url}
                previewThumbnail={proTool?.post_thumbnail?.url}
                attachmentFileType={proTool?.post_content?.file_type ?? "File"}
                watchLink={proTool?.course_session_content?.url}
                isProMember={userDetails?.is_pro_member}
                isProMemberVisible={proTool?.pro_member_visible}
              />
            ))}
          </ResponsiveCardEncloser>
        </div>
        <div
          className={classNames(
            "hidden xl:grid grid-cols-4",
            tools?.length < 2
              ? "hidden xxs:flex gap-4"
              : tools?.length < 3
              ? "hidden xxs:flex gap-4"
              : "hidden xl:flex gap-4"
          )}
        >
          {tools.map((proTool, index) => (
            <ProToolCard
              key={`protools-${index}`}
              title={proTool?.title}
              subTitle={proTool?.text_content}
              fileType={proTool?.post_content?.file_type ?? "File"}
              category={proTool?.category?.value}
              downloadLink={proTool?.post_content?.url}
              fileUrl={proTool?.post_content?.url}
              previewThumbnail={proTool?.post_thumbnail?.url}
              attachmentFileType={proTool?.post_content?.file_type ?? "File"}
              watchLink={proTool?.course_session_content?.url}
              isProMember={userDetails?.is_pro_member}
              isProMemberVisible={proTool?.pro_member_visible}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

ProToolSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  className: PropTypes.string,
  viewAllButtonClass: PropTypes.string,
  viewAllButtonSize: PropTypes.string,
  tools: PropTypes.array,
};
export default ProToolSection;
