import { useState, useEffect, useMemo } from "react";
import { CaretLeft, List } from "@phosphor-icons/react";
import SideNavMenu from "../Header/SideNavMenu";
import SearchBar from "../../stories/SearchBar";
import StyledButton from "../CommonComponents/StyledButton";
import PopupAllCoursesFilter from "../CommonComponents/AllCourseFilterResponsive";
import AllCoursesFilter from "../CommonComponents/AllCoursesFilters";
import AllCourseCard from "./AllCourseCard";
import Pagination from "../CommonComponents/Pagination";
import NavBar from "../CommonComponents/Navbar";
import Loader from "../Shimmer/Loader";
import {
  useAllCourses,
  useAllCoursesFilters,
} from "../../services/CourseDetails";
import NoDataFound from "../CommonComponents/NoDataFound";
import { navigate, useLocation } from "@reach/router";
import { User } from "../../store";
import ProUpgradeCard from "../../stories/ProUpgradeCard";
import { useProfile } from "../../context/userProvider";
import {
  ArrowsCounterClockwise,
  CalendarBlank,
  Certificate,
  Desktop,
  Files,
  Folders,
  Users,
} from "@phosphor-icons/react";
import Sidebar from "../../stories/Sidebar";

const AllCourses = () => {
  const entriesPerPage = 12;
  const isLoggedIn = !!User.getToken();
  const location = useLocation();
  const state = location?.state?.course_type || "";
  const { userDetails } = useProfile();

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [defaultState, setDefaultState] = useState("");
  const sidebarList = [
    {
      id: "dashboard",
      label: "Dashboard",
      normalIcon: <Desktop className="menu-icon" />,
      activeIcon: <Desktop className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/",
    },
    {
      id: "my_programs",
      label: "My Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/programs",
    },
    {
      id: "qpfp_certificant",
      label: "My QPFP Certification",
      normalIcon: <Certificate className="menu-icon" />,
      activeIcon: <Certificate className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/qpfp-certificant/dashboard",
      hide: userDetails?.qpfp_certification_status === "inactive",
    },
    {
      id: "all_programs",
      label: "All Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/all-courses",
    },
    {
      id: "nfp_toolbox",
      label: "NFP Toolbox & Resources",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/toolbox",
    },
    {
      id: "nfp-guest-list",
      label: "My Guest List",
      normalIcon: <List className="menu-icon" />,
      activeIcon: <List className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/guest-list",
    },
    {
      id: "nfp-calendar",
      label: "My Calendar",
      normalIcon: <CalendarBlank className="menu-icon" />,
      activeIcon: <CalendarBlank className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/nfp-calendar",
    },
    {
      id: "payments",
      label: "My Payments",
      normalIcon: <Files className="menu-icon" />,
      activeIcon: <Files className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/payments",
    },
    {
      id: "public_profile",
      label: "Public Profile",
      normalIcon: <Users className="menu-icon" />,
      activeIcon: <Users className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/public-profile",
    },
    {
      id: "exchange-forum",
      label: "Exchange forum",
      normalIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      activeIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      dropdownList: [],
      path: "/exchange",
    },
  ];

  useEffect(() => {
    sidebarList.forEach((item) => {
      if (item.dropdownList?.length === 0) {
        if (item.path === window.location.pathname) {
          setDefaultState(item.id);
        }
      } else {
        item.dropdownList.forEach((e, i) => {
          if (e.path === window.location.pathname) {
            setDefaultState(e.id);
          }
        });
      }
    });
  }, [window?.location?.pathname]);

  // Global filters for API calls
  const [filters, setFilters] = useState({
    category_id: [],
    course_type: [],
    duration_filter: [],
    course_level: [],
  });

  // Temporary filters for popup interaction
  const [tempFilters, setTempFilters] = useState(filters);

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const memoizedParams = useMemo(
    () => ({
      search: debouncedSearchQuery,
      ...filters,
    }),
    [debouncedSearchQuery, filters]
  );

  const { filters: allCourseFilterData } = useAllCoursesFilters({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });
  const {
    loading: allCourseLoading,
    error: allCourseError,
    courses: allCourseData,
    pagination,
  } = useAllCourses({}, memoizedParams, currentPage, entriesPerPage);

  useEffect(() => {
    if (state) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        course_type: [...filters?.course_type, state],
      }));
    }
  }, []);

  //  Existing handleFilterChange - updates global filters and triggers API call

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
    setCurrentPage(1);
  };

  //  New handleTempFilterChange - updates temporary filters for popup

  const handleTempFilterChange = (newFilters) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  /**
   * New handleApplyTempFilters - applies temporary filters to global filters
   */
  const handleApplyTempFilters = () => {
    setFilters(tempFilters);
    setCurrentPage(1); // Reset pagination
  };

  if (allCourseLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  const handleClearAllFilters = () => {
    setFilters({
      category_id: [],
      course_type: [],
      duration_filter: [],
      course_level: [],
    });
    setTempFilters({
      category_id: [],
      course_type: [],
      duration_filter: [],
      course_level: [],
    });
    setSearchQuery("");
    setCurrentPage(1);
  };

  return (
    <div>
      <NavBar
        mobileSideBarComponent={
          <Sidebar
            className={`fixed inset-0 z-50 flex lg:hidden bg-white transition-transform duration-300 ${"translate-x-0"}`}
            showRegisterCard={true}
            isLogoClickable={true}
            sidebarItems={sidebarList}
            onMenuClick={async (item) => {
              await navigate(item.path);
              setDefaultState(item.id);
              // setIsSidebarOpen(false); // Close sidebar on navigation
            }}
            infoBottomCard={
              userDetails?.side_bar_promotion_cta && (
                <ProUpgradeCard
                  className="bg-theme-gray-200"
                  heading={userDetails?.side_bar_promotion_cta?.heading}
                  description={userDetails?.side_bar_promotion_cta?.description}
                  cta={
                    <StyledButton
                      title={userDetails?.side_bar_promotion_cta?.cta_label}
                      handleClick={() => {
                        navigate(userDetails?.side_bar_promotion_cta?.cta_link);
                        // setIsSidebarOpen(false); // Close sidebar
                      }}
                    />
                  }
                  content_label={userDetails?.side_bar_promotion_cta?.title}
                  benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                    "\n"
                  )}
                />
              )
            }
            defaultState={defaultState}
          />
        }
      />
      <div className="hidden h-max lg:flex flex-col">
        {isLoggedIn && (
          <SideNavMenu
            infoBottomCard={
              userDetails?.side_bar_promotion_cta && (
                <ProUpgradeCard
                  className="bg-theme-gray-200"
                  heading={userDetails?.side_bar_promotion_cta?.heading}
                  description={userDetails?.side_bar_promotion_cta?.description}
                  cta={
                    <StyledButton
                      title={userDetails?.side_bar_promotion_cta?.cta_label}
                      handleClick={() => {
                        navigate(userDetails?.side_bar_promotion_cta?.cta_link);
                      }}
                    />
                  }
                  content_label={userDetails?.side_bar_promotion_cta?.title}
                  benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                    "\n"
                  )}
                />
              )
            }
          />
        )}
      </div>
      <div
        className={`flex justify-center w-full py-10 px-5 bg-theme-gray-25 min-h-screen`}
      >
        <div className="relative flex flex-col gap-5 max-w-7xl w-full">
          <div
            className="text-lg lg:text-2xl font-semibold flex items-center justify-start gap-1.5 w-full cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            <CaretLeft />
            <p>Back to Dashboard</p>
          </div>
          <div className="flex flex-col md:flex-row justify-between md:items-center gap-3 md:gap-5">
            <div className="flex flex-col gap-1.5 sm:gap-3">
              <p className="text-2xl sm:text-3xl lg:text-5xl font-bold">
                All Courses
              </p>
              <p className="text-theme-black-500 text-base lg:text-xl">
                Listing of all NFP courses that you can attend to learn and grow
              </p>
            </div>
            <div className="flex w-full md:max-w-[340px]">
              <SearchBar
                className="w-full border border-theme-gray-100 rounded-md"
                backgroundColor="bg-white"
                placeholder={"Search by Program"}
                searchTerm={searchQuery}
                onSearch={(val) => setSearchQuery(val)}
              />
            </div>
            <div className="md:hidden">
              <PopupAllCoursesFilter
                handleFilterChange={handleTempFilterChange} // Update temporary filters
                filters={tempFilters} // Use temporary filters
                courseCategoryFilters={
                  allCourseFilterData?.[0]?.course_category
                }
                courseDurationFilters={
                  allCourseFilterData?.[0]?.duration_filter
                }
                handleApplyFilters={handleApplyTempFilters}
                handleClearAllFilters={handleClearAllFilters}
              >
                <StyledButton
                  buttonStyle="transparent-primary"
                  title={"Filter Courses"}
                  textSize={"text-base"}
                />
              </PopupAllCoursesFilter>
            </div>
          </div>
          <div className="flex gap-5 justify-between">
            <div className="hidden md:flex">
              <AllCoursesFilter
                handleFilterChange={handleFilterChange} // Existing behavior
                filters={filters} // Global filters
                courseCategoryFilters={
                  allCourseFilterData?.[0]?.course_category
                }
                courseDurationFilters={
                  allCourseFilterData?.[0]?.duration_filter
                }
                handleClearAllFilters={handleClearAllFilters}
              />
            </div>
            <div className="flex flex-col gap-6 md:gap-10 w-full">
              {allCourseError || allCourseData.length === 0 ? (
                <NoDataFound />
              ) : (
                <>
                  <div className="grid xxs:grid-cols-2 sm:flex flex-wrap xl:grid xl:grid-cols-3 w-full gap-x-4 gap-y-6">
                    {allCourseData?.map((course, index) => (
                      <AllCourseCard
                        id={course?.id}
                        key={`course-card-${index}`}
                        title={course?.actual_name}
                        subTitle={course?.description}
                        category={course?.category?.value}
                        image={course?.course_logo}
                        status={course?.course_type_str}
                        progress={course?.course_completion_percentage}
                        rating={course?.average_rating}
                        lastActivity={course?.last_viewed_content?.last_seen}
                        duration={course?.course_duration}
                        level={course?.course_level}
                        courseType={course?.course_type}
                        hasRegistered={course?.has_registered}
                        hasStarted={course?.has_started}
                        isLoggedIn={isLoggedIn}
                        pricing={course?.course_fees}
                      />
                    ))}
                  </div>
                  <Pagination
                    totalEntries={pagination?.total_count}
                    entriesPerPage={entriesPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
