import PropTypes from "prop-types";
import {
  Check,
  Circle,
  DownloadSimple,
  Lock,
  PlayCircle,
  User,
  X,
} from "@phosphor-icons/react";
import React from "react";
import programImage from "./assets/svgs/NFP_logo.svg";
import Link from "./stories/Link";
import { CustomToast } from "./stories/Toast";
import moment from "moment";
import ImageComponent from "./stories/ImageComponent";
import promember_badge from "./assets/images/pro_badge.png";
import link_icon from "./assets/svgs/link.svg";
import _ from "lodash";
import { QPFPProgressBar } from "./stories/ProgressBar";
import { getDirectoryMemberURL } from "./util/getURLs";
import Tooltip from "./stories/Tooltip";
import { romanNumbers } from "./util/romanNumbers";
import UserProfileAvatar from "./stories/UserProfileAvatar";
import ProMemberBadge from "./assets/svgs/promember_badge_orange.svg";
import { downloadFile } from "./util/downloadFile";
import PopupImage from "./stories/PopupImage";

export const InvoiceColumns = [
  {
    name: "name",
    label: "PROGRAM NAME",
    rowCellClassName: "!py-5",
    renderCell: ({ rowValue }) => {
      return (
        <div className="flex items-center space-x-1 ">
          <img
            alt="program_logo"
            src={
              rowValue?.course_logo ??
              rowValue?.voluntary_contribution_logo?.signed_url ??
              programImage
            }
            className="object-contain w-8 min-w-[32px] h-8 mr-2"
          />
          <div className="flex flex-col">
            <div className="text-sm text-neutral-900">
              {rowValue?.program_name}
            </div>
            <div className="text-gray-500 text-xxs">
              {rowValue?.program_detail}
            </div>
          </div>
        </div>
      );
    },
  },
  { name: "invoice_number", label: "INVOICE NUMBER" },
  {
    name: "start_date",
    label: "START DATE",
    renderCell: ({ rowValue }) => {
      return moment(rowValue?.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY");
    },
  },
  {
    name: "amount",
    label: "AMOUNT",
    renderCell: ({ rowValue, column, rowIndex, columnIndex }) => {
      return `₹ ${
        rowValue[column.name]
          ? rowValue[column.name] === 0
            ? rowValue[column.name]
            : rowValue[column.name].toLocaleString("en-IN", {
                minimumFractionDigits: 0,
                minimumIntegerDigits: 2,
              })
          : 0
      }`;
    },
  },
  {
    name: "",
    label: "DOWNLOAD INVOICE",
    align: "center",
    renderCell: ({ rowValue }) => {
      return (
        <React.Fragment>
          <DownloadSimple
            onClick={() => {
              const url =
                process.env.REACT_APP_PROD_BASE_API_URL +
                (rowValue.program_detail === "Voluntary Contribution"
                  ? "/voluntary_purchases/"
                  : "/purchases/") +
                rowValue?.purchase_id +
                (rowValue?.program_detail === "Voluntary Contribution"
                  ? "/voluntary_download_invoice"
                  : "/download_invoice");
              fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const blobURL = window.URL.createObjectURL(blob);
                  const filename = rowValue?.invoice_number;
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = blobURL;
                  // the filename you want
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(blobURL);
                })
                .catch(() =>
                  CustomToast.error(
                    "Something went wrong!! Cannot download file"
                  )
                );
            }}
            className="mx-auto hover:cursor-pointer"
            color="#E10000"
            size={24}
            weight="fill"
          />
        </React.Fragment>
      );
    },
  },
];
export const leaderboardColumn = (avgBatchPercentage, qpfpProgressTracker) => [
  {
    name: "sr_no",
    label: "SR. No.",
    headingClassname: "!max-w-[10px]",
    rowCellClassName: "!min-w-fit !max-w-[20px]",
    renderCell: ({ rowIndex }) => {
      return rowIndex + 1 > 9 ? rowIndex + 1 : `0${rowIndex + 1}`;
    },
  },
  {
    name: "name",
    label: "NAME",
    headingClassname: "max-w-[100px]",
    rowCellClassName: "!min-w-fit !max-w-[130px]",
    align: "left",
    renderCell: ({ rowValue }) => {
      return (
        <div className="w-full flex flex-row items-center gap-4 text-center">
          <Link
            href={getDirectoryMemberURL(rowValue?.profile_slug)}
            target="_blank"
          >
            <UserProfileAvatar
              imageUrl={rowValue?.profile_picture}
              isLoggedInUser={false}
            />
          </Link>
          <div className="flex flex-col items-start md:text-sm">
            <div className="lg:text-base md:text-sm whitespace-nowrap">
              {rowValue?.display_name}
            </div>
            <div className="text-neutral-600 flex flex-row items-center gap-1 lg:text-sm">
              <div>{rowValue?.city}</div>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    name: "complete_percentage",
    label: "COMPLETION PERCENTAGE",
    headingClassname: "!text-center",
    align: "center",
    renderCell: ({ rowValue }) => {
      return (
        <div className="flex gap-2 w-full items-center">
          <div className="">
            {_.round(rowValue?.course_completion_percentage ?? 0)}%
          </div>
          <div className="flex gap-3 w-full items-center">
            {rowValue?.modules?.map((module, index) => (
              <Tooltip
                key={module.index}
                infoContent={`Level ${romanNumbers[index]}`}
                className="w-full"
                messageClassName="!min-w-fit"
              >
                <QPFPProgressBar
                  userPercent={
                    ((module?.module_complete_percentage ?? 0) * 100) /
                    (qpfpProgressTracker?.modules?.[index]?.total_percentage ??
                      1)
                  }
                  batchPercent={0}
                  level={index + 1}
                  barHeight="h-1"
                  showPosition={false}
                />
              </Tooltip>
            ))}
            <Tooltip
              key={module.index}
              infoContent="Final Exam"
              className="w-full"
              messageClassName="!min-w-fit"
            >
              <QPFPProgressBar
                userPercent={rowValue?.is_final_exam_completed ? 100 : 0}
                batchPercent={0}
                level={4}
                barHeight="h-1"
                showPosition={false}
              />
            </Tooltip>
          </div>
        </div>
      );
    },
  },
  {
    name: "track",
    label: "TRACK",
    headingClassname: "!max-w-[120px]",
    rowCellClassName: "!min-w-fit !max-w-[120px]",
    align: "center",
    renderCell: ({ rowValue }) => {
      const avgUserPercentage = rowValue?.modules?.reduce(
        (res, currV) => res + currV.module_complete_percentage,
        0
      );
      if (avgUserPercentage / avgBatchPercentage > 0.8) {
        return <span className="text-green-600 text-sm">On Track</span>;
      }
      if (avgUserPercentage / avgBatchPercentage > 0.4) {
        return <span className="text-theme-yellow text-sm">Progressing</span>;
      }
      return <span className="text-theme-red text-sm">Off Track</span>;
    },
  },
];

export const MyAceQuizColumn = (showNFPAceDetails) => [
  {
    name: "sr_no",
    label: "SR. No.",
    headingClassname: "max-w-[100px]",
    rowCellClassName: "!min-w-fit !max-w-[100px]",
    renderCell: ({ rowIndex }) => {
      return rowIndex + 1 > 9 ? rowIndex + 1 : `0${rowIndex + 1}`;
    },
  },
  {
    name: "display_date",
    label: "DATE",
    renderCell: ({ rowValue }) => {
      return moment(rowValue?.display_date, "DD-MM-YYYY").format("DD-MMM-YYYY");
    },
  },
  {
    name: "title",
    label: "SESSION NAME",
    headingClassname: "!w-2/8",
  },
  {
    name: "status",
    label: "YOUR STATUS",
    headingClassname: "!text-center",
    rowCellClassName: "!text-center",
    renderCell: ({ rowValue }) => {
      if (!rowValue?.is_played)
        return (
          <Tooltip
            infoContent={"Quiz not attempted"}
            className="w-full"
            messageClassName="!min-w-fit"
          >
            <div className="w-5 h-5 min-w-[20px] flex justify-center items-center text-base font-medium mx-auto text-yellow-600 rounded-full bg-yellow-400/30">
              !
            </div>
          </Tooltip>
        );
      if (rowValue?.has_cleared)
        return (
          <Tooltip
            infoContent={"Quiz Passed"}
            className="w-full"
            messageClassName="!min-w-fit"
          >
            <Check
              weight="bold"
              className="rounded-full mx-auto w-5 h-5 text-green-600 bg-green-400/30 px-0.5"
            />
          </Tooltip>
        );
      if (!rowValue?.has_cleared)
        return (
          <Tooltip
            infoContent={"Quiz Failed"}
            className="w-full"
            messageClassName="!min-w-fit"
          >
            <X
              weight="bold"
              className="rounded-full mx-auto w-5 h-5 text-red-600 bg-red-400/30 px-0.5"
            />
          </Tooltip>
        );
    },
  },
  {
    name: "obtained_score",
    label: "SCORE",
  },
  {
    name: "ace_points",
    label: "POINTS",
  },
  {
    name: "",
    label: showNFPAceDetails ? "QUIZ LINK" : "QUIZ RESULT",
    renderCell: ({ rowValue }) => {
      const quizLink = `/programs/${rowValue?.quiz_details?.course_id}/learn/${rowValue?.quiz_details?.course_session_content_id}`;
      return (
        <Link
          textColor="text-blue-600"
          activeTextColor="text-blue-600"
          showUnderLine={false}
          underLineOnlyOnHover={false}
          fontSize="sm"
          href={quizLink}
          className="font-normal leading-5 tracking-tight"
        >
          {rowValue?.is_played ? "Quiz Result" : "Quiz Link"}
        </Link>
      );
    },
  },
];

export const eventDelegatesColumn = () => [
  {
    name: "sr_no",
    label: "SR. No.",
    headingClassname: "max-w-[100px]",
    rowCellClassName: "!min-w-fit !max-w-[100px]",
    renderCell: ({ rowIndex }) => {
      return rowIndex + 1 > 9 ? rowIndex + 1 : `0${rowIndex + 1}`;
    },
  },
  {
    name: "first_name",
    label: "DELEGATE NAME",
    renderCell: ({ rowValue }) => {
      return (
        <div className="flex items-center">
          {rowValue?.first_name ?? "-"} {rowValue?.last_name}
          <div className="ml-3">
            {rowValue?.is_pro_member && (
              <img
                alt="pro"
                src={ProMemberBadge}
                className="object-contain w-4 h-4"
              />
            )}
          </div>
        </div>
      );
    },
  },
  {
    name: "firm_name",
    label: "Organization",
  },
  {
    name: "city_name",
    label: "CITY",
  },
  {
    name: "ticket_type",
    label: "TICKET",
    renderCell: ({ rowValue }) => {
      return rowValue?.ticket_type?.split("@")[0];
    },
  },
  {
    name: "is_pro_member",
    label: "MEMBER",
    renderCell: ({ rowValue }) => {
      return rowValue?.is_pro_member ? "ProMember" : "Non-Member";
    },
  },
  {
    name: "profile_slug",
    label: "PROFILE LINK",
    renderCell: ({ rowValue }) => {
      if (rowValue?.is_pro_member === false) {
        return "--";
      }

      return (
        <div className="flex items-center space-x-3">
          <Link
            textColor="text-blue-600"
            activeTextColor="text-blue-600"
            showUnderLine={false}
            underLineOnlyOnHover={false}
            fontSize="sm"
            className="font-normal leading-5 tracking-tight"
            href={getDirectoryMemberURL(rowValue?.profile_slug)}
            target="_blank"
          >
            PROfile
          </Link>
          <img alt="link" src={link_icon} className="object-contain w-3 h-3" />
        </div>
      );
    },
  },
];

export const AceQuizData = [
  {
    date: "02-01-2021",
    session_name: "ProTools - Popular Templates to download & Use",
    ace_points: "1",
  },
  {
    date: "02-01-2021",
    session_name: "ProTools - Popular Templates to download & Use",
    ace_points: "1",
  },
];

export const GuestEventColumn = [
  {
    name: "sr_no",
    label: "SR no.",
    headingClassname: "!w-1/12 ",
    rowCellClassName: " !min-w-fit",
    renderCell: ({ rowValue, rowIndex }) => {
      return rowIndex + 1;
    },
  },
  {
    name: "guest_name",
    label: "GUEST NAME",
    headingClassname: "!w-3/12",
    renderCell: ({ rowValue }) => {
      return `${rowValue?.first_name} ${rowValue?.last_name}`;
    },
  },
  {
    name: "mobile_number",
    label: "MOBILE NUMBER",
    headingClassname: "!w-2/12",
  },
  {
    name: "email",
    label: "EMAIL",
    headingClassname: "!w-3/12",
  },
  {
    name: "city",
    label: "CITY",
    headingClassname: "!w-2/12",
  },
  {
    name: "age",
    label: "AGE (IN YEAR)",
    headingClassname: "!w-1/12",
  },
];
export const ContactListColumn = [
  {
    name: "sr_no",
    label: "SR no.",
    headingClassname: "!w-1/12 ",
    rowCellClassName: " !min-w-fit",
    renderCell: ({ rowIndex }) => {
      return rowIndex + 1;
    },
  },
  {
    name: "guest_name",
    label: "CONTACT NAME",
    headingClassname: "!w-2/12",
    renderCell: ({ rowValue }) => {
      return `${rowValue?.first_name} ${rowValue?.last_name}`;
    },
  },
  {
    name: "mobile",
    label: "MOBILE NUMBER",
    headingClassname: "!w-1/12",
  },
  {
    name: "email",
    label: "EMAIL",
    headingClassname: "!w-2/12",
  },
  {
    name: "city",
    label: "CITY",
    headingClassname: "!w-1/12",
  },
  {
    name: "age",
    label: "AGE (IN YEAR)",
    headingClassname: "!w-1/12",
  },
  {
    name: "query",
    label: "Message",
    headingClassname: "!w-4/12",
    rowCellClassName: "break-all",
  },
];

export const AceEventColumn = [
  {
    name: "display_date",
    label: "DATE",
    renderCell: ({ rowValue }) => {
      return moment(rowValue?.display_date, "DD-MM-YYYY").format("DD-MMM-YYYY");
    },
  },
  {
    name: "event_name",
    label: "EVENT NAME",
  },
  {
    name: "points",
    label: "ACE POINTS",
  },
];
export const AceEventData = [
  {
    date: "03-02-2021",
    event_name: "ProTools - Popular Templates to download & Use",
    ace_points: "1",
  },
];

export const SubscriptionColumns = [
  {
    name: "name",
    label: "PROGRAM NAME",
    renderCell: ({ rowValue }) => {
      return rowValue?.name ?? "-";
    },
  },
  {
    name: "payment_status",
    label: "STATUS",
    renderCell: ({ rowValue }) => {
      switch (rowValue?.payment_status) {
        case "Paid":
          return <span className="text-sm text-green-600">Paid</span>;
        case "Overdue":
          return <span className="text-sm text-theme-red">Overdue</span>;
        case "Payment pending":
          return <span className="text-sm text-theme-yellow">Pending</span>;
        case "Free":
          return <span className="text-sm text-theme-orange">Free</span>;
        default:
          return <span className="text-sm text-theme-black-color">-</span>;
      }
    },
  },
  {
    name: "start_date",
    label: "START DATE",
    renderCell: ({ rowValue }) => {
      return rowValue?.start_date
        ? moment(rowValue?.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY")
        : "-";
    },
  },
  {
    name: "due_date",
    label: "NEXT DUE DATE",
    renderCell: ({ rowValue }) => {
      return rowValue?.due_date
        ? moment(rowValue?.due_date, "DD-MM-YYYY").format("DD-MMM-YYYY")
        : "-";
    },
  },
  {
    name: "amount",
    label: "AMOUNT",
    renderCell: ({ rowValue }) => {
      return rowValue?.amount ?? "-";
    },
  },
  {
    name: "action",
    label: "ACTION",
    renderCell: ({ rowValue }) => {
      return rowValue?.action ?? "-";
    },
  },
];
export const CorporateSubscriptionColumns = ({
  selectedQPFPModules,
  onClickSelectModule,
  removeSelectModule,
}) => [
  {
    name: "user_full_name",
    label: "MEMBER NAME",
    renderCell: ({ rowValue }) => {
      return rowValue?.user_full_name ?? "-";
    },
  },
  {
    name: "name",
    label: "PROGRAM NAME",
    renderCell: ({ rowValue }) => {
      return (
        <span>
          {rowValue?.name ?? "-"}
          <br></br>
          {rowValue?.subscription && (
            <span className="text-xs text-theme-black-300">
              - {rowValue?.subscription}
            </span>
          )}
        </span>
      );
    },
  },
  {
    name: "payment_status",
    label: "STATUS",
    renderCell: ({ rowValue }) => {
      switch (rowValue?.payment_status) {
        case "Paid":
          return <span className="text-sm text-green-600">Paid</span>;
        case "Overdue":
          return <span className="text-sm text-theme-red">Overdue</span>;
        case "Expires Soon":
          return <span className="text-sm text-theme-red">Expires Soon</span>;
        case "Payment pending":
          return (
            <span className="text-sm text-theme-yellow">Payment Pending</span>
          );
        case "Free":
          return <span className="text-sm text-theme-orange">Free</span>;
        default:
          return <span className="text-sm text-theme-black-color">-</span>;
      }
    },
  },
  {
    name: "start_date",
    label: "START DATE",
    renderCell: ({ rowValue }) => {
      return rowValue?.start_date
        ? moment(rowValue?.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY")
        : "-";
    },
  },
  {
    name: "due_date",
    label: "NEXT DUE DATE",
    renderCell: ({ rowValue }) => {
      return rowValue?.due_date
        ? moment(rowValue?.due_date, "DD-MM-YYYY").format("DD-MMM-YYYY")
        : "-";
    },
  },
  {
    name: "amount",
    label: "AMOUNT",
    renderCell: ({ rowValue }) => {
      if (rowValue?.course_type === "qpfp") {
        const module =
          selectedQPFPModules[
            `${rowValue?.entity_details.entity_type}_${rowValue?.entity_details.entity_id}`
          ];
        if (module) {
          return `₹ ${module.total_amount} `;
        }

        if (_.isEmpty(rowValue?.available_part_payment_option))
          return rowValue?.total_amount ? `₹ ${rowValue?.total_amount}` : "-";

        if (rowValue?.available_part_payment_option?.length === 1)
          return `₹ ${rowValue?.available_part_payment_option[0].total_amount}`;
        const amount1 = rowValue?.available_part_payment_option[0].total_amount;
        const amount2 = rowValue?.available_part_payment_option[1].total_amount;
        return amount1 > amount2
          ? `₹ ${amount2} - ₹ ${amount1}`
          : `₹ ${amount1} - ₹ ${amount2}`;
      }
      return rowValue?.total_amount ? `₹ ${rowValue?.total_amount}` : "-";
    },
  },
  {
    name: "action",
    label: "QPFP MODULE",
    renderCell: ({ rowValue, rowIndex }) => {
      if (
        rowValue?.course_type !== "qpfp" ||
        !["Pending", "Overdue", "Expires Soon"].includes(
          rowValue?.payment_status
        ) ||
        rowValue?.payment_details?.available_part_payment_option?.length < 2
      )
        return "-";
      const module =
        selectedQPFPModules[
          `${rowValue?.entity_details.entity_type}_${rowValue?.entity_details.entity_id}`
        ];
      if (module) {
        return (
          <div className="flex flex-col">
            <div className="text-sm text-theme-black-color">{module.label}</div>
            <div className="flex items-center gap-2 text-sm">
              <div
                className="cursor-pointer text-theme-red"
                onClick={() => onClickSelectModule({ rowValue, rowIndex })}
              >
                EDIT
              </div>
              <Circle size={4} weight="fill" />
              <div
                className="cursor-pointer text-theme-red"
                onClick={() => removeSelectModule({ rowValue, rowIndex })}
              >
                REMOVE
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          className="text-sm cursor-pointer text-theme-red"
          onClick={() => onClickSelectModule({ rowValue, rowIndex })}
        >
          SELECT MODULE
        </div>
      );
    },
  },
];
export const CorporatePaymentRequestColumns = ({
  selectedQPFPModules,
  onClickSelectModule,
  removeSelectModule,
}) => [
  {
    name: "user_full_name",
    label: "MEMBER NAME",
    renderCell: ({ rowValue }) => {
      return rowValue?.user_full_name ?? "-";
    },
  },
  {
    name: "name",
    label: "PROGRAM NAME",
    renderCell: ({ rowValue }) => {
      return rowValue?.course.course_name ?? "-";
    },
  },
  {
    name: "status",
    label: "STATUS",
    renderCell: ({ rowValue }) => {
      switch (rowValue?.status) {
        case "approved":
          return <span className="text-sm text-green-600">Paid</span>;
        case "rejected":
          return <span className="text-sm text-theme-red">Paid by user</span>;
        case "pending":
          return <span className="text-sm text-theme-yellow">Pending</span>;
        default:
          return <span className="text-sm text-theme-black-color">-</span>;
      }
    },
  },
  {
    name: "created_at",
    label: "REQUESTED ON",
    renderCell: ({ rowValue }) => {
      return rowValue?.created_at
        ? moment(rowValue?.created_at).format("DD-MMM-YYYY")
        : "-";
    },
  },
  {
    name: "payment_details.amount",
    label: "AMOUNT",
    renderCell: ({ rowValue }) => {
      if (rowValue?.course.course_type === "qpfp") {
        const module =
          selectedQPFPModules[
            `${rowValue?.entity_details.entity_type}_${rowValue?.entity_details.entity_id}`
          ];
        if (module) {
          return `₹ ${module.total_amount} `;
        }
        if (_.isEmpty(rowValue?.payment_details?.available_part_payment_option))
          return rowValue?.payment_details?.total_amount
            ? `₹ ${rowValue?.payment_details?.total_amount}`
            : "-";

        if (
          rowValue?.payment_details?.available_part_payment_option?.length === 1
        )
          return `₹ ${rowValue?.payment_details?.available_part_payment_option[0].total_amount}`;

        const amount1 =
          rowValue?.payment_details?.available_part_payment_option[0]
            .total_amount;
        const amount2 =
          rowValue?.payment_details?.available_part_payment_option[1]
            .total_amount;
        return amount1 > amount2
          ? `₹ ${amount2} - ₹ ${amount1}`
          : `₹ ${amount1} - ₹ ${amount2}`;
      }
      return rowValue?.payment_details?.total_amount
        ? `₹ ${rowValue?.payment_details?.total_amount}`
        : "-";
    },
  },
  {
    name: "action",
    label: "QPFP MODULE",
    renderCell: ({ rowValue, rowIndex }) => {
      if (
        rowValue?.course.course_type !== "qpfp" ||
        !["pending", "Overdue", "Expires Soon"].includes(rowValue?.status) ||
        (rowValue?.payment_details?.available_part_payment_option ?? [])
          ?.length < 2
      )
        return "-";

      const module =
        selectedQPFPModules[
          `${rowValue?.entity_details.entity_type}_${rowValue?.entity_details.entity_id}`
        ];
      if (module) {
        return (
          <div className="flex flex-col">
            <div className="text-sm text-theme-black-color">{module.label}</div>
            <div className="flex items-center gap-2 text-sm">
              <div
                className="cursor-pointer text-theme-red"
                onClick={() => onClickSelectModule({ rowValue, rowIndex })}
              >
                EDIT
              </div>
              <Circle size={4} weight="fill" />
              <div
                className="cursor-pointer text-theme-red"
                onClick={() => removeSelectModule({ rowValue, rowIndex })}
              >
                REMOVE
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          className="text-sm cursor-pointer text-theme-red"
          onClick={() => onClickSelectModule({ rowValue, rowIndex })}
        >
          SELECT MODULE
        </div>
      );
    },
  },
];
export const CorporateInvoiceColumns = [
  {
    name: "name",
    label: "PROGRAM NAME(S)",
    rowCellClassName: "!py-5",
    renderCell: ({ rowValue }) => {
      return (
        <div className="flex items-center space-x-1 ">
          <img
            alt="program_logo"
            src={rowValue?.firm_logo ?? programImage}
            className="object-cover w-8 min-w-[32px] h-8 mr-2"
          ></img>
          <div className="flex flex-col">
            <div className="text-sm text-neutral-900">
              {rowValue?.corporate_purchase_items.length > 1
                ? `${rowValue?.corporate_purchase_items[0].course_name.substring(
                    0,
                    36
                  )}${
                    rowValue?.corporate_purchase_items[0].course_name.length >
                    36
                      ? "..."
                      : ""
                  }`
                : rowValue?.corporate_purchase_items[0].course_name}
              <b>
                {`${
                  rowValue?.corporate_purchase_items.length > 1
                    ? " + " +
                      (rowValue?.corporate_purchase_items.length - 1) +
                      " course(s)"
                    : ""
                }`}
              </b>
            </div>
            <div className="text-gray-500 text-xxs">
              {rowValue?.program_detail}
            </div>
          </div>
        </div>
      );
    },
  },
  { name: "invoice_number", label: "INVOICE NUMBER" },
  {
    name: "start_date",
    label: "START DATE",
    renderCell: ({ rowValue }) => {
      return moment(rowValue?.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY");
    },
  },
  {
    name: "amount",
    label: "AMOUNT",
    renderCell: ({ rowValue, column }) => {
      return `₹ ${
        rowValue[column.name]
          ? rowValue[column.name] === 0
            ? rowValue[column.name]
            : rowValue[column.name].toLocaleString("en-IN", {
                minimumFractionDigits: 0,
                minimumIntegerDigits: 2,
              })
          : 0
      }`;
    },
  },
  {
    name: "",
    label: "DOWNLOAD INVOICE",
    align: "center",
    renderCell: ({ rowValue }) => {
      return (
        <React.Fragment>
          <DownloadSimple
            onClick={() => {
              const url =
                process.env.REACT_APP_PROD_BASE_API_URL +
                (rowValue.program_detail === "Voluntary Contribution"
                  ? "/voluntary_purchases/"
                  : "/purchases/") +
                rowValue?.purchase_id +
                (rowValue?.program_detail === "Voluntary Contribution"
                  ? "/voluntary_download_invoice"
                  : "/download_invoice");
              fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const blobURL = window.URL.createObjectURL(blob);
                  const filename = rowValue?.invoice_number;
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = blobURL;
                  // the filename you want
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(blobURL);
                })
                .catch(() =>
                  CustomToast.error(
                    "Something went wrong!! Cannot download file"
                  )
                );
            }}
            className="mx-auto hover:cursor-pointer"
            color="#E10000"
            size={24}
            weight="fill"
          />
        </React.Fragment>
      );
    },
  },
];

export const InvoiceData = [
  {
    programImage: programImage,
    programName: "QPFP Certification Program",
    programType: "Subscription",
    status: "Ongoing",
    startDate: "02-01-2022",
    nextDueDate: "02-01-2022",
    amount: "₹6,742",
    action: "Pay",
  },
  {
    programImage: programImage,
    programName: "ProMembership Program",
    status: "Ongoing",
    programType: "Subscription",
    startDate: "02-01-2022",
    nextDueDate: "02-01-2022",
    amount: "₹6,742",
    action: "View",
  },
  {
    programImage: programImage,
    programName: "ProMembership Program",
    status: "Ongoing",
    programType: "Subscription",
    startDate: "02-01-2022",
    nextDueDate: "02-01-2022",
    amount: "₹6,742",
    action: "View",
  },
  {
    programImage: programImage,
    programName: "QPFP Certification Program",
    status: "Ongoing",
    programType: "Subscription",
    startDate: "02-01-2022",
    nextDueDate: "02-06-2022",
    amount: "₹6,742",
    action: "",
  },
];

export const SubscriptionData = [
  {
    programImage: programImage,
    programName: "QPFP Certification Program",
    status: "Ongoing",
    startDate: "02-01-2022",
    nextDueDate: "02-01-2022",
    amount: "₹6,742",
    action: "Pay",
  },
  {
    programImage: programImage,
    programName: "ProMembership Program",
    status: "Ongoing",
    startDate: "02-01-2022",
    nextDueDate: "02-01-2022",
    amount: "₹6,742",
    action: "View",
  },
  {
    programImage: programImage,
    programName: "QPFP Certification Program",
    status: "Ongoing",
    startDate: "02-021-2022",
    nextDueDate: "02-06-2022",
    amount: "₹6,742",
    action: "",
  },
];

export const CorporateMembersColumn = ({ onRemoveClick, admin_id }) => [
  {
    name: "user.first_name",
    label: "Member Name",
    renderCell: ({ rowValue }) => {
      return (
        <div className="flex items-center gap-3">
          <div className="relative min-w-[28px]">
            <ImageComponent
              alt="profile-icon"
              src={rowValue?.user?.profile_picture?.signed_url ?? ""}
              errorComponent={
                <div className="flex items-center justify-center w-6 h-6 rounded-full lg:h-7 lg:w-7">
                  <User size={20} weight="bold" className="text-gray-400" />
                </div>
              }
              className="object-cover w-6 h-6 rounded-full lg:h-6 lg:w-6"
            />
            {rowValue?.user.is_pro_member && (
              <img
                alt="pro"
                src={promember_badge}
                className="absolute bottom-0 right-0 object-contain w-3 h-3"
              />
            )}
          </div>
          {rowValue?.user.first_name ?? "-"} {rowValue?.user.last_name}
        </div>
      );
    },
  },
  {
    name: "mobile_no",
    label: "Mobile number",
    renderCell: ({ rowValue }) => {
      return rowValue?.user.phone_number ?? "-";
    },
  },
  {
    name: "email",
    label: "Email",
    renderCell: ({ rowValue }) => {
      return rowValue?.user.email;
    },
  },
  {
    name: "city",
    label: "City",
    renderCell: ({ rowValue }) => {
      return rowValue?.user.city_name ?? "-";
    },
  },
  {
    name: "status",
    label: "Invitation Status",
    renderCell: ({ rowValue }) => {
      switch (rowValue?.status) {
        case "approved":
          return <span className="text-sm text-green-600">Accepted</span>;
        case "rejected":
          return <span className="text-sm text-theme-red">Rejected</span>;
        case "pending":
        default:
          return <span className="text-sm text-theme-yellow">Pending</span>;
      }
    },
  },
  {
    name: "action",
    label: "Action",
    renderCell: ({ rowValue, rowIndex }) => {
      if (admin_id === rowValue?.user.id) return "-";
      return (
        <span
          onClick={() => onRemoveClick({ rowValue, rowIndex })}
          className="text-sm tracking-tight cursor-pointer text-theme-red"
        >
          REMOVE
        </span>
      );
    },
  },
];

export const compareMembershipData = [
  {
    heading: "Eligibility Criteria",
    data: [
      {
        label: "Graduation (Any Stream)",
        type: "text",
        basic: "-",
        community: "Required",
        qpfp: "Required",
        promembership: "Required",
      },
      {
        label: "Industry Exam (Any One)",
        infoText: "Hi I am inside tooltip",
        type: "text",
        basic: "-",
        community: "-",
        qpfp: "Required",
        promembership: "Required",
      },
      {
        label: "Professional Education (Any One)",
        infoText: "Hi I am inside tooltip",
        type: "text",
        basic: "-",
        community: "-",
        qpfp: "-",
        promembership: "Required",
      },
    ],
  },
  {
    heading: "Program Fees",
    data: [
      {
        label: "Program Fees",
        type: "text",
        basic: "Free",
        community: "₹4,225 + gst",
        qpfp: "₹48,000 + gst",
        promembership: "₹13,000 + gst",
      },
      {
        label: "Program Validity",
        type: "text",
        basic: "Lifetime Free",
        community: "1 year validity",
        qpfp: "One time payment",
        promembership: "Annual Renewal",
      },
    ],
  },
  {
    heading: "Recorded Courses Included",
    data: [
      {
        label: "PFP Career Awareness Program",
        type: "checkbox",
        basic: true,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "PFP Growth Partners Program",
        type: "checkbox",
        basic: true,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "PFP Success Foundation Program",
        type: "checkbox",
        basic: false,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "PFP Practice Management Program",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
    ],
  },
  {
    heading: "Live Sessions Included (with Rec)",
    data: [
      {
        label: "Growth Partners Showcase",
        type: "checkbox",
        basic: true,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "PFP SuccessTalks",
        type: "checkbox",
        basic: false,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "UpSkills & MasterClasses",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
    ],
  },
  {
    heading: "Annual Events Included",
    data: [
      {
        label: "NFP Exposition & InnoVision",
        type: "checkbox",
        basic: false,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "FundManager OpenTalks",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
      {
        label: "National Annual Summit",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
    ],
  },
  {
    heading: "Public Directory & Public Awareness",
    data: [
      {
        label: "Creation of NFP Public Profile",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
      {
        label: "Listing on NFP Public Directory",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
      {
        label: "Public Awareness Programs",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
    ],
  },
  {
    heading: "Other Benefits",
    data: [
      {
        label: "NFP ProTools",
        type: "text",
        basic: "3 Tools",
        community: "9 Tools",
        qpfp: "50 Tools",
        promembership: "25 Tools",
      },
      {
        label: "NFP Exchange",
        type: "checkbox",
        basic: false,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "NFP Quizzes",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
      {
        label: "NFP ClientConnect",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
    ],
  },
  {
    heading: "QPFP Program Benefits",
    data: [
      {
        label: "100+ Hours of Training",
        type: "checkbox",
        basic: false,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "50+ ProTools / Calculators",
        type: "checkbox",
        basic: false,
        community: true,
        qpfp: true,
        promembership: true,
      },
      {
        label: "50+ Tests & Exams",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: false,
        promembership: true,
      },
      {
        label: "Proctored Final Exam",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: true,
        promembership: false,
      },
      {
        label: "Digital Courseware / Handouts",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: true,
        promembership: false,
      },
      {
        label: "Coaching Calls with Coaches",
        type: "checkbox",
        basic: false,
        community: false,
        qpfp: true,
        promembership: false,
      },
    ],
  },
];

export const genderOptions = [
  { id: "male", gender: "Male" },
  { id: "female", gender: "Female" },
  // { id: "transgender", gender: "Transgender" },
  { id: "other", gender: "Other" },
];

export const oathData = [
  {
    title: "Expertise",
    description:
      "My clients trust me for being an expert in the field of personal finance. I shall keep myself updated with required knowledge & skills to provide expert recommendations to my clients.",
  },
  {
    title: "Transparency",
    description:
      "My clients trust me for being transparent in all my dealings with them. I shall voluntarily disclose all material facts & conflicts of interest in a fair & transparent manner to my clients.",
  },
  {
    title: "Holistic",
    description:
      "My clients trust me for empathizing with their dreams, goals, aspirations & anxieties. I shall be mindful, compassionate and respectful of all their feelings around both money & life.",
  },
  {
    title: "Integrity",
    description:
      "My clients trust me for being honest in our relationship. I shall always maintain integrity of our professional relationship and never do anything which is a let-down to my clients.",
  },
  {
    title: "ClientFirst",
    description:
      "My clients trust me for providing truly unbiased &   independent advice. I shall ensure that my clients interest always comes before my personal gains and advice is void of any biases.",
  },
  {
    title: "Solutions",
    description:
      "My clients trust me for providing solutions to their problems, needs & requirements and not merely execute their transactions. I shall be diligent in offering the best possible solutions.",
  },
];

export const qpfpSiderbarLinks = [
  {
    label: "QPFP Dashboard",
    href: "/qpfp-certificant/dashboard",
  },
  {
    label: "QPFP Certificate & Badges",
    href: "/qpfp-certificant/certificates",
  },
  {
    label: "QPFP Brand Resources",
    href: "/qpfp-certificant/brand-guidlines",
  },
  {
    label: "QPFP Program & ProTools",
    href: "/qpfp-certificant/courseware",
  },
  {
    label: "ACE Points Tracker",
    href: "/qpfp-certificant/ace-points-tracker",
  },
  {
    label: "ACE Program & Tests",
    href: "/qpfp-certificant/ace-program-and-tests",
  },
  {
    label: "Edit NFP Profile",
    href: "/public-profile",
  },
  // {
  //   label: "ProMembership Dashboard",
  //   href: "#",
  // },
  {
    label: "Renew QPFP Certification",
    href: "/qpfp-certificant/renewal",
  },
  {
    label: "NFP Dashboard",
    href: "/",
  },
];
export const sidebarLinks = [
  {
    label: "Dashboard",
    href: "/",
  },
  {
    label: "My Courses",
    href: "/my-programs",
  },
  {
    label: "My QPFP Certification",
    href: "/qpfp-certificant/dashboard",
  },
  {
    label: "All Courses",
    href: "/all-courses",
  },
  {
    label: "NFP Toolbox & Resources",
    href: "/toolbox",
  },
  {
    label: "My Payments",
    href: "/payments",
  },
  {
    label: "My Calendar",
    href: "/nfp-calendar",
  },
  {
    label: "My Guests List",
    href: "/guest-list",
  },
  {
    label: "Public Profile",
    href: "/public-profile",
  },
  {
    label: "Exchange Forum",
    href: "/exchange",
  },
  // {
  //   label: "Register / Pay",
  //   href: "/register",
  // },
];
export const NonQpfpSidebarLinks = [
  {
    label: "Dashboard",
    href: "/",
  },
  {
    label: "My Courses",
    href: "/my-programs",
  },
  {
    label: "All Courses",
    href: "/all-courses",
  },
  {
    label: "NFP Toolbox & Resources",
    href: "/toolbox",
  },
  {
    label: "My Payments",
    href: "/payments",
  },
  {
    label: "My Calendar",
    href: "/nfp-calendar",
  },
  {
    label: "My Guests List",
    href: "/guest-list",
  },
  {
    label: "Public Profile",
    href: "/public-profile",
  },
  {
    label: "Exchange Forum",
    href: "/exchange",
  },
  // {
  //   label: "Register / Pay",
  //   href: "/register",
  // },
];

export const QPFPCertificationPoints = [
  { title: "Download QPFP certificate & badges", value: false },
  { title: "Access updated courseware & protools", value: false },
  { title: "Access & complete ACE points / quizzes", value: false },
  { title: "Download QPFP marks usage resources", value: false },
  { title: "Renew Your QPFP Certification", value: false },
];

export const CourseTypeFilters = [
  { value: "Basic Account", id: "basic_account" },
  { value: "ProMember", id: "pro_member_series" },
  { value: "Paid Programs", id: "paid_programs" },
];

export const CourseLevelFilters = [
  { value: "Beginner", id: "beginner" },
  { value: "Intermediate", id: "intermediate" },
  { value: "Advanced", id: "advance" },
];
export const CourseDurationFilters = [
  { value: "Short < 3 hours", id: "short" },
  { value: "Medium > 3 Hours ", id: "medium" },
  { value: "Long > 10 Hours", id: "long" },
];

export const QPFPFilter = [{ value: "QPFP", id: "qpfp" }];

export const ProToolTableColumns = [
  {
    name: "sr_no",
    label: "S.NO",
    renderCell: ({ rowIndex }) => {
      return rowIndex + 1 > 9 ? rowIndex + 1 : `0${rowIndex + 1}`;
    },
  },
  {
    name: "protool_or_resource_name",
    label: "ProTool / Resource Name",
    renderCell: ({ rowValue }) => {
      if (rowValue?.title)
        return (
          <PopupImage
            type={rowValue?.post_content?.file_type ?? ""}
            imgSrc={rowValue?.post_content?.url ?? ""}
            canAccess={
              rowValue?.basic_visible ||
              (rowValue?.is_pro_member && rowValue?.pro_member_visible)
            }
          >
            <div className="flex items-center gap-2 cursor-pointer hover:underline">
              {!rowValue?.is_pro_member && rowValue?.pro_member_visible && (
                <Lock
                  weight="fill"
                  color="#E10000"
                  size={16}
                  className="min-w-4"
                />
              )}
              {rowValue?.title}
              {rowValue?.tooltip && (
                <Tooltip
                  infoContent={rowValue?.tooltip}
                  className="w-fit"
                  messageClassName="!min-w-fit"
                  isFill={false}
                ></Tooltip>
              )}
            </div>
          </PopupImage>
        );
      return "-";
    },
  },
  {
    name: "plan",
    label: "PLAN",
    renderCell: ({ rowValue }) => (
      <UserPlanTag
        plan={
          rowValue?.pro_member_visible
            ? "pro_member"
            : rowValue?.qpfp_visible
            ? "qpfp_member"
            : rowValue?.community_visible
            ? "community"
            : rowValue?.plan
        }
      />
    ),
  },
  {
    name: "resource_type",
    label: "Resource Type",
    align: "center",
    renderCell: ({ rowValue }) => {
      return (
        <p className="first-letter:capitalize">{rowValue?.post_type ?? "-"}</p>
      );
    },
  },
  {
    name: "file_type",
    label: "File Type",
    align: "center",
    renderCell: ({ rowValue }) => {
      return rowValue?.post_content?.file_type ?? "-";
    },
  },
  {
    name: "category",
    label: "Category",
    align: "center",
    renderCell: ({ rowValue }) => {
      return rowValue?.category?.value ?? "-";
    },
  },
  {
    name: "watch_demo",
    label: "WATCH DEMO",
    align: "center",
    renderCell: ({ rowValue }) => {
      const encodedUrl = encodeURIComponent(
        rowValue?.course_session_content?.url
      );
      const encodedDownloadUrl = encodeURIComponent(
        rowValue?.post_content?.url
      );

      if (rowValue?.course_session_content?.url && rowValue?.isLoggedIn)
        return (
          <Link
            className="flex items-center justify-center gap-2 text-theme-red cursor-pointer"
            target="_blank"
            textColor="text-theme-red"
            activeTextColor="text-theme-red"
            fontSize="lg"
            showUnderLine={false}
            href={
              "/toolbox/protool-video-preview/" +
              encodedUrl +
              "/" +
              encodedDownloadUrl
            }
          >
            <PlayCircle size={20} />
            <p>Play Video</p>
          </Link>
        );
      return "-";
    },
  },
  {
    name: "downloAD",
    label: "DOWNLOAD",
    align: "center",
    renderCell: ({ rowValue }) => {
      if (rowValue?.post_content?.url)
        return (
          <div
            className="flex items-center justify-center gap-2.5 text-theme-red cursor-pointer"
            onClick={() => {
              rowValue?.isLoggedIn
                ? !rowValue?.is_pro_member && rowValue?.pro_member_visible
                  ? CustomToast.error("You are not a Pro Member")
                  : downloadFile(rowValue?.post_content?.url)
                : CustomToast.error("Please login to download");
            }}
          >
            <DownloadSimple size={20} />
            <p>Download</p>
          </div>
        );
      return "-";
    },
  },
];
export const ClientConnectTableColumns = [
  {
    name: "sr_no",
    label: "S.NO",
    renderCell: ({ rowIndex }) => {
      return rowIndex + 1 > 9 ? rowIndex + 1 : `0${rowIndex + 1}`;
    },
  },
  {
    name: "protool_or_resource_name",
    label: "Client Connect / Resource Name",
    renderCell: ({ rowValue }) => {
      if (rowValue?.title)
        return (
          <PopupImage
            type={rowValue?.post_content?.file_type ?? ""}
            imgSrc={rowValue?.post_content?.url ?? ""}
            canAccess={
              rowValue?.basic_visible ||
              (rowValue?.is_pro_member && rowValue?.pro_member_visible)
            }
          >
            <div className="flex items-center gap-2 cursor-pointer hover:underline">
              {!rowValue?.is_pro_member && rowValue?.pro_member_visible && (
                <Lock
                  weight="fill"
                  color="#E10000"
                  size={16}
                  className="min-w-4"
                />
              )}
              {rowValue?.title}
              {rowValue?.tooltip && (
                <Tooltip
                  infoContent={rowValue?.tooltip}
                  className="w-fit"
                  messageClassName="!min-w-fit"
                  isFill={false}
                ></Tooltip>
              )}
            </div>
          </PopupImage>
        );
      return "-";
    },
  },
  {
    name: "plan",
    label: "PLAN",
    renderCell: ({ rowValue }) => (
      <UserPlanTag
        plan={
          rowValue?.pro_member_visible
            ? "pro_member"
            : rowValue?.qpfp_visible
            ? "qpfp_member"
            : rowValue?.community_visible
            ? "community"
            : rowValue?.plan
        }
      />
    ),
  },
  {
    name: "file_type",
    label: "File Type",
    renderCell: ({ rowValue }) => {
      return rowValue?.post_content?.file_type ?? "-";
    },
  },
  {
    name: "category",
    label: "Category",
    renderCell: ({ rowValue }) => {
      return rowValue?.category?.value ?? "-";
    },
  },
  {
    name: "downloAD",
    label: "DOWNLOAD",
    align: "center",
    renderCell: ({ rowValue }) => {
      if (rowValue?.post_content?.url)
        return (
          <div
            className="flex items-center justify-center gap-2.5 text-theme-red"
            onClick={() => {
              !rowValue?.is_pro_member && rowValue?.pro_member_visible
                ? CustomToast.error("You are not a Pro Member")
                : downloadFile(rowValue?.post_content?.url);
            }}
          >
            <DownloadSimple size={20} />
            <p>Download</p>
          </div>
        );
      return "-";
    },
  },
];

export const ReactSlickSettings = {
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  centerPadding: "10px",
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
      },
    },
    {
      breakpoint: 1008,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 680,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const UserPlanTag = ({ plan, isSmall = false }) => {
  switch (plan) {
    case "community":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-green`}
        >
          Community
        </div>
      );
    case "pro_member":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-red`}
        >
          ProMember
        </div>
      );
    case "pro_member_series":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-red`}
        >
          ProMember
        </div>
      );
    case "qpfp_member":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-yellow`}
        >
          QPFP
        </div>
      );
    default:
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-green`}
        >
          Basic Account
        </div>
      );
  }
};
UserPlanTag.propTypes = {
  plan: PropTypes.string,
  isSmall: PropTypes.bool,
};
export const CourseTypeTag = ({ courseType, isSmall = false }) => {
  switch (courseType) {
    case "community":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-green`}
        >
          Community
        </div>
      );
    case "pro_member":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-red`}
        >
          ProMember
        </div>
      );
    case "pro_member_series":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-red`}
        >
          ProMember
        </div>
      );
    case "qpfp":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-yellow`}
        >
          QPFP
        </div>
      );
    case "national_event":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-yellow`}
        >
          Event
        </div>
      );
    case "event":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-yellow`}
        >
          Event
        </div>
      );
    case "demo":
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-yellow`}
        >
          Demo
        </div>
      );
    default:
      return (
        <div
          className={`w-fit text-center ${
            isSmall ? "text-[10px] px-1 py-0.5" : "text-xs px-2 py-1"
          } font-medium rounded-full text-white bg-theme-green`}
        >
          Free
        </div>
      );
  }
};
CourseTypeTag.propTypes = {
  courseType: PropTypes.string,
  isSmall: PropTypes.bool,
};

export const NoneMemberStatus = [
  "not_registered",
  "incomplete_registration",
  "not_paid",
]; // expires_soon // paid // approved // provisional // overdue
