import PropTypes from "prop-types";
import { CaretDown } from "@phosphor-icons/react";
import { useState } from "react";

export const NonFormikDropDown = ({
  placeHolder = "Select",
  options,
  selectedValue,
  setSelectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full max-w-96 relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full bg-white text-theme-gray-600 py-2 px-2 rounded-md"
      >
        {selectedValue?.label ? (
          <p className="text-theme-black-color">{selectedValue?.label} </p>
        ) : (
          <p>{placeHolder}</p>
        )}
        <CaretDown
          weight="fill"
          className="w-5 h-5 text-theme-theme-gray-600"
        />
      </div>
      {isOpen && (
        <div className="flex flex-col absolute top-12 left-0 w-full h-fit bg-white text-theme-black-color rounded-md shadow-nav-shadow text-sm">
          {options?.length > 0 ? (
            options?.map((option, index) => (
              <div
                className="px-2 py-2 hover:bg-theme-gray-100-opacity-10 cursor-pointer"
                key={index}
                onClick={() => {
                  setSelectedValue(option);
                  setIsOpen(false);
                }}
              >
                {option?.label}
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center h-full">
              No Options available{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

NonFormikDropDown.propTypes = {
  placeHolder: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.object,
  setSelectedValue: PropTypes.func,
};
