import PropTypes from "prop-types";
import React, { useState } from "react";
import play_icon from "../assets/svgs/play.svg";
import { LockSimple } from "@phosphor-icons/react";
import { useNavigate } from "@reach/router";
import ImageComponent from "./ImageComponent";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { CustomToast } from "./Toast";
import classNames from "classnames";
import CompleteModuleModal from "../components/Modals/CompleteModuleModal";
import RenewModal from "../components/Modals/RenewModal";
import CustomLink from "./Link";
import _ from "lodash";
// This is the session card of the module
const ModuleCard = ({
  percentCompleted = 0,
  href = "",
  eventDetails = {},
  isLocked,
  hasCompletedUnlockedModule,
  cummulativeIndex = 1,
  hasMadeFullPayment,
  remainingAmount,
  availablePartPaymentOptions = [],
  allowUserToSkipRenewal = false,
  isClientConnect = false,
  authorName,
  courseName,
  courseDate,
  firstSessionContentId,
  courseTitle,
  course_id,
  session_thumnail_src = "",
  status = "",
  entityType,
  onClick,
  curriculumRef = {},
  refreshCallback = () => {},
  hasRegistered = true,
  moduleDuration,
}) => {
  const navigate = useNavigate();
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);

  const MyCard = () => {
    return (
      <div
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }

          if (isLocked) {
            if (!hasCompletedUnlockedModule) {
              setShowCompleteModal(true);
            } else if (!hasRegistered) {
              CustomToast.info(
                "You are not registered for this course. Please register first and Start the course."
              );
            } else {
              setShowRenewModal(!hasMadeFullPayment);
            }
            return;
          }

          if (isClientConnect) {
            navigate(href);
            return;
          }
          if (!firstSessionContentId) {
            if (hasRegistered) {
              navigate("/programs/" + course_id + "/learn");
              return;
            } else {
              CustomToast.error(
                "No content is available in this session. Wait for course author to upload it"
              );
              return;
            }
          }
          if (!isLocked)
            navigate(
              "/programs/" + course_id + "/learn/" + firstSessionContentId,
              {
                state: {
                  curriculumData: {
                    course: curriculumRef?.current,
                    event_details: eventDetails,
                  },
                },
              }
            );
        }}
        className="w-full min-w-[128px] h-32 p-3 bg-white border border-gray-200 rounded-2xl cursor-pointer drop-shadow-sm"
      >
        <div className="relative flex h-full space-x-3">
          <div className="relative w-1/3">
            <div className="flex items-center justify-center w-full h-full">
              <ImageComponent
                src={session_thumnail_src}
                errorComponent={
                  <div className="w-full h-full bg-gray-200 rounded-lg"></div>
                }
                className={classNames(
                  "h-full w-full !bg-white border !border-gray-200 rounded-lg",
                  isClientConnect ? "object-contain" : "object-cover"
                )}
              />
              {!isClientConnect && (
                <img
                  alt="icon"
                  src={play_icon}
                  className="absolute z-10 w-8 h-8 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col w-2/3">
            <div className="flex items-start">
              {!isClientConnect && _.isNumber(cummulativeIndex) && (
                <div className="w-6 h-full mr-2 min-w-[24px] lg:w-7 lg:h-7">
                  <CircularProgressbarWithChildren
                    minValue={0}
                    maxValue={100}
                    value={percentCompleted}
                    strokeWidth={8}
                    styles={{
                      root: {},
                      path: {
                        stroke: "#F36C04",
                        strokeLinecap: "round",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                      },
                      trail: {
                        stroke: `#CCCCCC`,
                        strokeLinecap: "butt",
                      },
                    }}
                  >
                    <div className="text-xs text-center lg:leading-6">
                      {cummulativeIndex}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )}
              <div className="text-sm font-bold leading-4 lg:leading-5 tracking-tight lg:text-base text-black capitalize line-clamp-2 md:text-base max-h-[32px] lg:max-h-[40px]">
                {courseTitle}
              </div>
              <ShowLock isLocked={isLocked} />
            </div>
            <div
              className={classNames(
                "mt-2 text-xs leading-4 lg:leading-5 tracking-tight lg:text-base md:flex max-h-[16px] lg:max-h-[24px] text-theme-black-color",
                !isClientConnect &&
                  _.isNumber(cummulativeIndex) &&
                  "ml-8 lg:ml-9 line-clamp-1"
              )}
            >
              {moduleDuration ?? courseDate}
            </div>
            <div
              className={classNames(
                "text-xs tracking-tight lg:leading-5 md:flex text-theme-gray-500 lg:text-sm",
                !isClientConnect &&
                  _.isNumber(cummulativeIndex) &&
                  "ml-8 lg:ml-9 "
              )}
            >
              {authorName}
            </div>
          </div>
          {status !== "" && <ShowStatus status={status} isLocked={isLocked} />}
        </div>
        {showCompleteModal && (
          <CompleteModuleModal
            open={showCompleteModal}
            onClose={() => setShowCompleteModal(false)}
          />
        )}
        {showRenewModal && (
          <RenewModal
            key={showRenewModal}
            open={showRenewModal}
            entityType={entityType}
            entityId={course_id}
            allowUserToSkipRenewal={allowUserToSkipRenewal}
            onClickSkipRenewal={() => {
              if (onClick) {
                onClick();
                return;
              }
              if (isClientConnect) {
                navigate(href, {
                  state: {
                    curriculumData: {
                      course: curriculumRef?.current,
                      event_details: eventDetails,
                    },
                  },
                });
                return;
              }

              navigate(
                "/programs/" + course_id + "/learn/" + firstSessionContentId,
                {
                  state: {
                    curriculumData: {
                      course: curriculumRef?.current,
                      event_details: eventDetails,
                    },
                  },
                }
              );
            }}
            courseName={courseName}
            remainingAmount={remainingAmount}
            availablePartPaymentOptions={availablePartPaymentOptions}
            refreshCallback={refreshCallback}
            onClose={() => {
              setShowRenewModal(false);
            }}
          />
        )}
      </div>
    );
  };

  if (onClick || isLocked || (!isClientConnect && !firstSessionContentId)) {
    return <MyCard />;
  }
  return (
    <CustomLink
      href={
        isClientConnect
          ? href
          : firstSessionContentId
          ? "/programs/" + course_id + "/learn/" + firstSessionContentId
          : null
      }
      showUnderLine={false}
      underLineOnlyOnHover={false}
      activeTextColor=""
      // state={{
      //   curriculumData: {
      //     course: curriculumRef?.current,
      //     event_details: eventDetails,
      //   },
      // }}
    >
      <MyCard />
    </CustomLink>
  );
};

const ShowLock = ({ isLocked }) => {
  if (!isLocked) return null;
  return (
    <LockSimple
      weight="fill"
      className="ml-auto min-w-[18px] text-sm font-semibold leading-5 tracking-tighter md:text-lg lg:text-md text-theme-red"
    />
  );
};

ShowLock.propTypes = { isLocked: PropTypes.bool };

const ShowStatus = ({ status, isLocked }) => {
  if (isLocked) {
    return (
      <div className="absolute bottom-0 right-0 flex text-xs uppercase bg-gray-200 py-2 px-3 rounded-full text-theme-black-color">
        UPCOMING
      </div>
    );
  }
  switch (status) {
    case "WATCH NOW":
      return (
        <div className="absolute bottom-0 right-0 flex text-xs uppercase bg-theme-yellow/20 py-2 px-3 rounded-full text-theme-yellow">
          WATCH NOW
        </div>
      );
    case "COMPLETED":
      return (
        <div className="absolute bottom-0 right-0 flex text-xs uppercase bg-green-600/20 py-2 px-3 rounded-full text-green-600">
          COMPLETED
        </div>
      );
    case "RESUME":
      return (
        <div className="absolute bottom-0 right-0 flex text-xs uppercase bg-pro-member-color/20 py-2 px-3 rounded-full text-pro-member-color">
          RESUME
        </div>
      );

    default:
      return (
        <div className="absolute bottom-0 right-0 flex text-xs uppercase bg-gray-200 py-2 px-3 rounded-full text-theme-black-color">
          {status}
        </div>
      );
  }
};

ShowStatus.propTypes = { status: PropTypes.string, isLocked: PropTypes.bool };

ModuleCard.defaultProps = {
  isLocked: false,
  authorName: "",
  courseDate: "",
  courseTitle: "",
};

ModuleCard.propTypes = {
  allowUserToSkipRenewal: PropTypes.bool,
  authorName: PropTypes.any,
  availablePartPaymentOptions: PropTypes.array,
  courseDate: PropTypes.string,
  courseName: PropTypes.string,
  courseTitle: PropTypes.string,
  course_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cummulativeIndex: PropTypes.number,
  curriculumRef: PropTypes.object,
  entityType: PropTypes.string,
  eventDetails: PropTypes.object,
  firstSessionContentId: PropTypes.number,
  hasCompletedUnlockedModule: PropTypes.bool,
  hasMadeFullPayment: PropTypes.bool,
  href: PropTypes.string,
  isClientConnect: PropTypes.bool,
  isLocked: PropTypes.bool,
  onClick: PropTypes.func,
  percentCompleted: PropTypes.number,
  refreshCallback: PropTypes.func,
  remainingAmount: PropTypes.number,
  session_thumnail_src: PropTypes.string,
  status: PropTypes.string,
  hasRegistered: PropTypes.bool,
  moduleDuration: PropTypes.string,
};

export default ModuleCard;
