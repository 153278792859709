import { useState } from "react";
import Breadcrumb from "../../stories/Breadcrumb";
import SearchBar from "../../stories/SearchBar";
import UpdatedTable from "../CommonComponents/UpdatedTable";
import { ClientConnectTableColumns } from "../../Constants";
import NoDataFound from "../CommonComponents/NoDataFound";
import usePosts from "../../hooks/usePost";
import AppSkeleton from "../AppSkeleton";
import _ from "lodash";

const ClientConnectNewPage = () => {
  const entriesPerPage = 20;
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPageNumber] = useState(1);

  const breadCrumbData = [
    { name: "Dashboard", to: "/" },
    {
      name: "Client Connect",
      to: "/client-connect",
      current: true,
    },
  ];

  const { loading, error, posts, pagination } = usePosts(
    "client_connect",
    "",
    page,
    searchQuery,
    entriesPerPage
  );

  // if (loading) {
  //   return (
  //     <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
  //       <Loader />
  //     </div>
  //   );
  // }

  return (
    <AppSkeleton>
      <div>
        <div
          className={`flex justify-center w-full py-10 px-5 bg-theme-gray-25 min-h-screen`}
        >
          <div className="flex flex-col gap-5 max-w-7xl w-full">
            <div className="flex flex-col sm:flex-row items-center justify-end w-full">
              <div className="flex flex-col gap-1.5 justify-center h-fit w-full">
                <p className="text-2xl font-bold">Client Connect</p>
                <Breadcrumb pages={breadCrumbData} type="chevron" />
              </div>
              <div className="flex items-center justify-end w-full md:max-w-lg rounded-md overflow-hidden">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={"Search by name"}
                  searchTerm={searchQuery}
                  onSearch={_.debounce((val) => setSearchQuery(val), 700)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 text-theme-black-500">
              <p className="text-sm">
                Learn, download and use these NFP Client Connect which will help
                you in practice management and client engagement
              </p>
            </div>
            {posts?.length === 0 || error ? (
              <NoDataFound subTitle={""} />
            ) : (
              <div className="flex flex-col gap-2">
                <UpdatedTable
                  columns={ClientConnectTableColumns}
                  isLoading={loading}
                  totalEntries={pagination?.total_count}
                  onPageChange={(page) => {
                    setPageNumber(page);
                  }}
                  data={posts}
                  entriesPerPage={entriesPerPage}
                  currentPage={page}
                  headerTitle={"NFP Client Connect "}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default ClientConnectNewPage;
