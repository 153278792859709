/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import Breadcrumb from "../../stories/Breadcrumb";
import StyledButton from "../CommonComponents/StyledButton";
import { DownloadSimple } from "@phosphor-icons/react";
import ProToolSection from "../CommonComponents/ProToolSection";
import VideoPlayer from "../../stories/VideoPlayer";
import usePosts from "../../hooks/usePost";
import { useEffect, useState } from "react";
import Loader from "../Shimmer/Loader";
import AppSkeleton from "../AppSkeleton";
import { downloadFile } from "../../util/downloadFile";
import { User } from "../../store";

const ProToolVideoPreview = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = !!User.getToken();

  const [url, setUrl] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const breadCrumbData = [
    { name: "Dashboard", to: "/" },
    { name: "My Programs", to: "/my-programs" },
    {
      name: "NFP Toolbox & Resources",
      to: "/toolbox",
      current: true,
    },
  ];

  const loggedOutBreadCrumbData = [
    { name: "Dashboard", to: "/" },
    {
      name: "NFP Toolbox & Resources",
      to: "/toolbox",
      current: true,
    },
  ];

  const { loading, posts } = usePosts("protool", "", 1, "", 4);
  useEffect(() => {
    if (props) {
      setUrl(decodeURIComponent(props?.url));
      setDownloadUrl(decodeURIComponent(props?.downloadUrl));
      setIsLoading(false);
    }
  }, [props]);
  if (isLoading) {
    return (
      <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <AppSkeleton>
      <div>
        <div
          className={`flex justify-center w-full py-10 px-5 bg-theme-gray-25 lg:min-h-screen`}
        >
          <div className="flex flex-col gap-6 max-w-7xl w-full">
            <div className="flex flex-col sm:flex-row gap-5 items-center max-w-7xl w-full">
              <div className="flex flex-col gap-1.5 justify-center h-fit w-full">
                <p className="text-2xl font-bold">NFP Toolbox & Resources</p>
                <Breadcrumb
                  pages={isLoggedIn ? breadCrumbData : loggedOutBreadCrumbData}
                  type="chevron"
                />
              </div>
              <div className="flex items-center justify-end h-fit w-full sm:max-w-52 rounded-md overflow-hidden">
                {downloadUrl && (
                  <StyledButton
                    title={"Download"}
                    icon={<DownloadSimple size={20} />}
                    handleClick={() => {
                      downloadFile(downloadUrl);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1 text-theme-black-500">
              <p className="text-xl font-medium">Capital Gains Calculator</p>
              <p className="text-sm">
                Learn, download and use these NFP ProTools and Resources which
                will help you in practice management and client engagement
              </p>
            </div>
            <VideoPlayer
              src={url}
              className="w-full h-full"
              startTime={0}
              onProgress={(duration) => {}}
              onDuration={(duration) => {}}
              onEnded={(e) => {}}
            />
            {posts.length > 0 && !loading && (
              <ProToolSection
                title={"Explore More"}
                subTitle={
                  "Explore a diverse range of courses designed to empower your learning "
                }
                viewAllButtonClass={"transparent-primary"}
                viewAllButtonSize="text-xl"
                tools={posts}
              />
            )}
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};
ProToolVideoPreview.propTypes = {
  props: PropTypes.object,
};
export default ProToolVideoPreview;
