/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Breadcrumb from "../../stories/Breadcrumb";
import SearchBar from "../../stories/SearchBar";
import ProtoolsAndResourcesTable from "../CommonComponents/UpdatedTable";
import { ProToolTableColumns } from "../../Constants";
import Loader from "../Shimmer/Loader";
import NoDataFound from "../CommonComponents/NoDataFound";
import useProtoolsAndResources from "../../hooks/useProtoolsAndResources";
import AppSkeleton from "../AppSkeleton";
import { CaretDown } from "@phosphor-icons/react";
import { NonFormikDropDown } from "../CommonComponents/NonFormikDropdown";
import _ from "lodash";

const ProToolsAndResources = () => {
  const entriesPerPage = 20;
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPageNumber] = useState(1);
  const [filter, setFilter] = useState({});
  const [query, setQuery] = useState(
    "post_type[]=protool&post_type[]=resource"
  );

  const generateQuery = () => {
    return `post_type[]=protool&post_type[]=resource${
      filter?.value ? `&${filter?.value}=true` : ""
    }`;
  };

  const breadCrumbData = [
    { name: "Dashboard", to: "/" },
    {
      name: "NFP Toolbox & Resources",
      to: "/toolbox",
      current: true,
    },
  ];

  // Update query state when filter changes
  useEffect(() => {
    setQuery(generateQuery());
  }, [filter]);

  const { loading, error, posts, pagination } = useProtoolsAndResources(
    query, // Use query state instead of generateQuery()
    page,
    searchQuery,
    entriesPerPage
  );

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  // if (loading) {
  //   return (
  //     <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
  //       <Loader />
  //     </div>
  //   );
  // }

  return (
    <AppSkeleton openLoginModal={true}>
      <div>
        <div
          className={`flex justify-center w-full py-10 px-5 bg-theme-gray-25 min-h-screen`}
        >
          <div className="flex flex-col gap-5 max-w-7xl w-full">
            <div className="flex flex-col sm:flex-row items-center justify-between w-full">
              <div className="flex flex-col gap-1.5 justify-center h-fit w-full">
                <p className="text-2xl font-bold">NFP Toolbox & Resources</p>
                <Breadcrumb pages={breadCrumbData} type="chevron" />
              </div>
              <div className="flex items-center justify-end w-full md:max-w-lg rounded-md overflow-hidden">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={"Search by name"}
                  searchTerm={searchQuery}
                  onSearch={_.debounce((val) => setSearchQuery(val), 700)}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-1 justify-between text-theme-black-500">
              <p className="text-sm">
                Download & Access ready-to-use ProTools (Excel Templates),
                Resources, and calculators to simplify and elevate your
                practice.
              </p>
              <NonFormikDropDown
                placeHolder="Filter"
                options={[
                  { label: "Basic", value: "basic_visible" },
                  { label: "ProMember", value: "pro_member_visible" },
                  // { label: "QPFP", value: "qpfp_visible" },
                  // { label: "Community", value: "community_visible" },
                ]}
                selectedValue={filter}
                setSelectedValue={(value) => {
                  setFilter(value);
                }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              {/* <p className="hidden 2lg:block text-xs font-medium text-theme-black-500">
                These tools are available only to QPFP current batch candidates
                & past certificants*
              </p> */}
              {posts?.length === 0 || error ? (
                <NoDataFound subTitle={""} />
              ) : (
                <ProtoolsAndResourcesTable
                  columns={ProToolTableColumns}
                  isLoading={loading}
                  totalEntries={pagination?.total_count}
                  onPageChange={(page) => {
                    setPageNumber(page);
                  }}
                  data={posts?.sort((a, b) => {
                    return a?.pro_member_visible === b?.pro_member_visible
                      ? 0
                      : b?.pro_member_visible
                      ? -1
                      : 1;
                  })}
                  entriesPerPage={entriesPerPage}
                  currentPage={page}
                  headerTitle={"NFP Toolbox & Resources"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default ProToolsAndResources;
