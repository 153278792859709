/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import API from "../api/manager";
import config from "../api/config";

const useProtoolsAndResources = (postType, page, searchQuery, perPage) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [lastSearchQuery, setLastSearchQuery] = useState("");
  const [lastPostType, setLastPostType] = useState("");
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    setLoading(true);
    setError(false);

    // Check if any of the key parameters have changed
    if (
      lastPage !== page ||
      lastSearchQuery !== searchQuery ||
      lastPostType !== postType
    ) {
      setLastPage(page);
      setLastPostType(postType);

      // Reset posts if search query or post type changes
      if (lastSearchQuery !== searchQuery || lastPostType !== postType) {
        setLastSearchQuery(searchQuery);
        setPosts([]);
      }

      API(
        {
          method: "GET",
          url: config.urls.protoolsAndResources.posts(
            postType,
            page,
            searchQuery,
            perPage ?? 10
          ),
        },
        true
      )
        .then((res) => {
          setPosts(res?.posts);
          setHasMore(!res.pagination.last_page);
          setPagination(res?.pagination);
        })
        .catch((e) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [page, searchQuery, postType, perPage]); // Added postType and perPage to dependencies

  return { loading, error, posts, hasMore, pagination };
};

export default useProtoolsAndResources;
