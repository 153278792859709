/* eslint-disable no-unused-vars */
import classNames from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";
import { CircleNotch, Lock } from "@phosphor-icons/react";
import Pagination from "./Pagination";
import { UserPlanTag } from "../../Constants";
import StyledButton from "./StyledButton";
import { DownloadSimple, PlayCircle } from "@phosphor-icons/react";
import { downloadFile } from "../../util/downloadFile";
import { navigate } from "@reach/router";
import Tooltip from "../../stories/Tooltip";
import { useProfile } from "../../context/userProvider";
import { CustomToast } from "../../stories/Toast";
import { User } from "../../store";
import PopupImage from "../../stories/PopupImage";

const UpdatedTable = ({
  columns = [],
  data = [],
  isLoading,
  totalEntries,
  onPageChange,
  entriesPerPage,
  currentPage,
  headerTitle,
}) => {
  const { userDetails } = useProfile();
  const isLoggedIn = !!User.getToken();

  return (
    <div>
      <table className="hidden xl:inline-table w-full table-auto">
        <thead>
          {headerTitle && (
            <tr className={"bg-theme-black-color w-full "}>
              <th
                scope="col"
                colSpan={columns.length}
                className={classNames(
                  "px-6 py-3 text-xs font-medium uppercase bg-theme-orange text-white"
                )}
              >
                {headerTitle}
              </th>
            </tr>
          )}
          <tr className={"bg-theme-black-color"}>
            {columns.map((item, i) => {
              return (
                <th
                  key={`${item.label}-${i}`}
                  scope="col"
                  className={classNames(
                    "px-6 py-3 text-xs font-medium uppercase bg-theme-black-color border-b text-white",
                    item.headingClassname || "",
                    item.align === "center"
                      ? "text-center"
                      : item.align === "right"
                      ? "text-right"
                      : "text-left"
                  )}
                >
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white">
          {isLoading ? (
            <tr className="w-full h-40">
              <td colSpan={columns.length}>
                <CircleNotch
                  className="mx-auto animate-spin"
                  size={30}
                  weight="fill"
                />
              </td>
            </tr>
          ) : _.isEmpty(data) ? (
            <tr className="border-b border-gray-200 text-theme-black-color">
              <td
                colSpan={columns.length}
                className="px-6 py-6 text-sm text-center"
              >
                No data
              </td>
            </tr>
          ) : (
            data?.map((item, i) => {
              return (
                <tr
                  key={`id-${Math.random().toString(16).slice(2)}-${i}`}
                  className="border-b border-gray-200 text-theme-black-color"
                >
                  {columns.map((col, j) => {
                    if (col.renderCell)
                      return (
                        <td
                          key={`td-${col.name ?? col.id}-${
                            item.id ??
                            item.uid ??
                            "id-" + Math.random().toString(16).slice(2)
                          }-${i}`}
                          className={classNames(
                            "px-6 py-6 min-w-[150px] text-sm first-letter:capitalize",
                            col.rowCellClassName,
                            col.align === "center"
                              ? "text-center"
                              : col.align === "right"
                              ? "text-right"
                              : "text-left"
                          )}
                        >
                          {col.renderCell({
                            rowValue: { ...item, ...userDetails, isLoggedIn },
                            column: col,
                            rowIndex: (currentPage - 1) * entriesPerPage + i,
                            columnIndex: j,
                          })}
                        </td>
                      );
                    return (
                      <td
                        key={`td1-${
                          item.id ??
                          item.uid ??
                          "id-" + Math.random().toString(16).slice(2)
                        }-${j}`}
                        className={classNames(
                          "px-6 min-w-[150px] py-6 text-sm cursor-pointer",
                          col.rowCellClassName,
                          col.align === "center"
                            ? "text-center"
                            : col.align === "right"
                            ? "text-right"
                            : "text-left"
                        )}
                      >
                        <PopupImage imgSrc={item[col.name]} type="image">
                          {item[col.name]}
                        </PopupImage>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
        {!_.isEmpty(data) && (
          <tfoot>
            <tr className="">
              <td colSpan={columns.length} className="py-6">
                <Pagination
                  totalEntries={totalEntries}
                  entriesPerPage={entriesPerPage}
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  setCurrentPage={onPageChange}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      <div className="flex flex-col xl:hidden gap-4">
        <div className="flex items-center justify-center px-6 py-3 text-xs font-medium uppercase bg-theme-orange text-white">
          {headerTitle}
        </div>
        {data.map((item, i) => {
          return (
            <div
              key={"pro-tool-card" + i}
              className="flex flex-col gap-[14px] border border-black-opacity-10 rounded-[10px] w-full p-3"
            >
              <div className="flex flex-col gap-2.5 cursor-pointer">
                <PopupImage
                  type={item?.post_content?.file_type ?? ""}
                  imgSrc={item?.post_content?.url ?? ""}
                  canAccess={
                    item?.basic_visible ||
                    (userDetails?.is_pro_member && item?.pro_member_visible)
                  }
                >
                  <div className="flex items-center gap-2 hover:underline">
                    {!userDetails?.is_pro_member &&
                      item?.pro_member_visible && (
                        <Lock
                          weight="fill"
                          color="#E10000"
                          size={16}
                          className="min-w-4"
                        />
                      )}

                    {item?.title}
                    {item?.tooltip && (
                      <Tooltip
                        infoContent={item?.tooltip}
                        className="w-fit"
                        messageClassName="!min-w-fit"
                        isFill={false}
                      ></Tooltip>
                    )}
                  </div>
                </PopupImage>
                <UserPlanTag
                  plan={
                    item?.pro_member_visible
                      ? "pro_member"
                      : item?.qpfp_visible
                      ? "qpfp_member"
                      : item?.community_visible
                      ? "community"
                      : item.plan
                  }
                />
              </div>
              <div className="grid xs:grid-cols-2 gap-3">
                <DetailColumn
                  title={"File Type"}
                  subTitle={item?.post_content?.file_type ?? "-"}
                />
                <DetailColumn
                  title={"Category"}
                  subTitle={item?.category?.value ?? "-"}
                />
                <DetailColumn
                  title={"Resource Type"}
                  subTitle={item?.post_type ?? "-"}
                />
              </div>
              <div
                className={`grid ${
                  item?.post_type !== "client_connect" && "xs:grid-cols-2"
                } gap-2`}
              >
                {item?.post_type !== "client_connect" &&
                  (item?.course_session_content?.url && isLoggedIn ? (
                    <StyledButton
                      textSize={"text-base"}
                      buttonStyle="transparent-secondary"
                      handleClick={() => {
                        navigate(
                          "/toolbox/protool-video-preview/" +
                            encodeURIComponent(
                              item?.course_session_content?.url
                            ) +
                            "/" +
                            encodeURIComponent(item?.post_content?.url)
                        );
                      }}
                      title={"Play Video"}
                      padding={"py-2 xs:py-0"}
                      icon={
                        <div className="mr-2">
                          <PlayCircle
                            size={20}
                            className="min-w-[20px] text-theme-red"
                          />
                        </div>
                      }
                    />
                  ) : (
                    <p className="text-center text-theme-red">-</p>
                  ))}
                {item?.post_content?.url ? (
                  <StyledButton
                    textSize={"text-base"}
                    buttonStyle="transparent-secondary"
                    handleClick={() => {
                      isLoggedIn
                        ? !userDetails?.is_pro_member &&
                          item?.pro_member_visible
                          ? CustomToast.error("You are not a Pro Member")
                          : downloadFile(item?.post_content?.url)
                        : CustomToast.error("Please login to download");
                    }}
                    title={"Download"}
                    icon={
                      <div className="mr-2">
                        <DownloadSimple
                          weight="fill"
                          size={20}
                          className="min-w-[20px] text-theme-red"
                        />
                      </div>
                    }
                    customClasses={classNames(
                      "flex items-center justify-center text-theme-red",
                      item?.post_type !== "client_connect" &&
                        "border-t-2 xs:border-t-0 xs:border-l-2 border-theme-gray-500"
                    )}
                    padding={"px-2 py-2 xs:py-0"}
                  />
                ) : (
                  <p className="text-center text-theme-red">-</p>
                )}
              </div>
            </div>
          );
        })}
        {!_.isEmpty(data) && (
          <Pagination
            totalEntries={totalEntries}
            entriesPerPage={entriesPerPage}
            onPageChange={onPageChange}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

const DetailColumn = ({ title, subTitle }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-xs text-theme-gray-500 font-medium">{title}</p>
      <p className="text-sm text-theme-black-color first-letter:capitalize">
        {subTitle}
      </p>
    </div>
  );
};

DetailColumn.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

UpdatedTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  totalEntries: PropTypes.number,
  entriesPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  headerTitle: PropTypes.string,
};

export default UpdatedTable;
